import { showMessage, success, failed } from "../utils/message";
import {  countBuiltyCharges, countNewHandlingCharges } from "./utils/count";
import { acrossFun } from "./utils/helpers";
import { BookingError } from "../validators/err";
import { Map as IMap } from "immutable";

import {
  SET_DOCKET_FROM_EWAYBILL,
  RESET_EWAYBILL_DATA,
  SET_ROUTES,
  UPDATE_BUILTY,
  SET_PACKAGE_ERROR_FLAG_FALSE,
  SET_SETTINGS,
  BUILTY_UPDATE_ACK,
  BUILTY_UPDATE_REQ,
  PACKAGE_UPDATE_ACK,
  PAYMENT_UPDATE_ACK,
  REFRESH_BOOKING,
  GET_BRANCHES_BY_COMPANY,
  ID_FETCH_ACK,
  BILL_FETCH_ACK,
  DOCKETS_FETCH_ACK,
  SENDER_GST_INVALID,
  RECEIVER_GST_INVALID,
  RESET_SENDER_ADDRESS,
  RESET_RECEIVER_ADDRESS,
  HIDE_EWAYBILL_LOADER,
  SHOW_EWAYBILL_LOADER,
  SHOW_GST_LOADER,
  HIDE_GST_LOADER,
  CREDIT_NUMBER_HIDE_LOADER,
  CREDIT_NUMBER_SHOW_LOADER,
  SHOW_GST_LOADING_FOR_RECEIVER,
  HIDE_GST_LOADING_FOR_RECEIVER,
  SET_SENDER_ADDRESS_FROM_OPBRANCH,
  SET_SENDER_ADDRESS_FROM_OTHER,
  SET_RECEIVER_ADDRESS_FROM_OPBRANCH,
  SET_RECEIVER_ADDRESS_FROM_OTHER,
  TOUCH_SENDER,
  TOUCH_RECEIVER,
  // BookingActionTypes,
  BOOK_BILL,
  BOOK_PURPOSE,
  BOOK_DOCKET,
  BOOK_EWB,
  BOOK_SENDER,
  BOOK_SET_SENDER,
  BOOK_SET_SENDER_VALIDATE,
  BOOK_SET_RECEIVER_VALIDATE,
  BOOK_RESET_SENDER,
  SET_RECEIVER_SUGGESTIONS,
  SET_RATE_SUGGESTIONS,
  SET_PACKAGE_SUGGESTIONS,
  BOOK_SET_RECEIVER,
  BOOK_RECEIVER,
  SET_DOCKET,
  RESET_SENDER_NAME,
  RESET_REMARKS,
  RESET_RECEIVER_NAME,
  BOOK_COL_MONTH,
  // BOOK_RATE,
  BOOK_PACK,
  BOOK_HOW_COL,
  PICKUP_CHARGE_CHANGE,
  BOOK_RISK_CHANGE,
  BOOK_REMARKS_CHANGE,
  BOOK_PICKUP_CONTACT,
  BOOK_GOODS_CHANGE,
  // BOOK_INS_CHANGE,
  BOOK_VALUE_CHARGE_CHANGE,
  BOOK_COL_CHANGE,
  BOOKING_REFRESH,
  BOOKING_EDIT_PACKAGE,
  BOOK_ADD_PACKAGE,
  BOOK_DEL_PACKAGE,
  ADD_PACKAGE_SIZE,
  // DEL_PACKAGE_SIZE,
  SET_ACROSS,
  SET_LR,
  SET_ORANGE_LR,
  BOOK_BRANCHES,
  BOOK_CHANGE_DELIVERY_TYPE,
  BOOK_CHANGE_FEDEX_SERVICE_TYPE,
  SET_FEDEX_SERVICE_OPTIONS,
  BOOK_DEST_BRANCH,
  // BOOK_DEST_ROUTE,
  BOOK_DEST_SERVICE,
  RESET_EDIT,
  BOOK_PAY_MODE,
  NONCASH_PAYMENT_TYPE,
  NONCASH_TXN_ID,
  NONCASH_TXN_IMAGE,
  CREDIT_NUM_CHANGE,
  BOOK_CREDIT_SET,
  BOOK_CREDIT_RESET,
  CHANGE_TO_CREDIT_SUCCESS,
  VALIDATION_ERROR,
  SPECIFIC_DELIVERY,
  SET_SENDER_BY_GST,
  SET_RECEIVER_BY_GST,
  BOOK_RESET_RECEIVER,
  BOOK_SET_IS_SENDER_DISABLE,
  BOOK_SET_FOC_VERIFICATION,
  BOOK_SET_SENDER_VERIFICATION,
  BOOK_SET_IS_RECEIVER_DISABLE,
  BOOK_SET_VERIFICATION,
  BOOK_SET_EMPLOYEE_VERIFICATION,
  SET_UPDATE_PKG_FETCHDOCKET,
  SET_DOOR_DELIVERY,
  RESET_DOOR_DELIVERY,
  BOOK_SET_FIXRATECUSTOMER,
  BOOK_RESET_FIXRATECUSTOMER,
  BOOK_SET_INDIVIDUALFLEET,
  SET_RATE_ID,
  SET_LAST_BOOKING,
  SET_DOOR_DELIVERY_DATA,
  NONCASH_TXNS_NUMBER,
  ADD_MULTIPLE_EWB,
  REMOVE_MULTIPLE_EWB,
  RESET_MULTIPLE_EWB,
  SET_MULTIPLE_EWAYBILL_DATA,
  RESET_PARTICULAR_EWAYBILL_DATA,
  CHANGE_MULTIPLE_EWAYBILL_MANUAL_DATA,
  SET_PARTICULAR_EWB,
  BOOK_CONTACT_DATA,
  SET_HANDLING_CHARGE_SLAB,
  SET_ONLINE_PAYMENT_STATUS,
  SET_SENDER_FORCE_NAME_REMOVE_DUE_TO_GST,
  SET_RECEIVER_FORCE_NAME_REMOVE_DUE_TO_GST,
  SET_SENDER_GST_REMOVE_REQ_DATA,
  SET_RECEIVER_GST_REMOVE_REQ_DATA,
  SET_SENDER_GST_CONTACTS,
  SET_RECEIVER_GST_CONTACTS,
  SET_SENDER_SECONDARY_CONTACT_REQ_DATA,
  SET_RECEIVER_SECONDARY_CONTACT_REQ_DATA,
  SET_GENERAL_WARNING
} from "../constants/booking";

import { isArrayCheck } from "../containers/generics/CheckArray";
import { toast } from "react-toastify";

const toRSOption = (value: string) =>
  value ? { label: value, value: value } : null;

const initCredit = {
  balance: null,
  company: null,
  customer: null,
  permissions: [],
  fromBranches: [],
  toBranches: [],
};

const colReport = {
  bookings: [],
  expenses: [],
  deliveries: [],
  sumOfExpense: 0,
  sumOfGross: 0,
};

const initState = {
  bookingCounters: {
    dockets: 0,
    packages: 0,
    amount: 0,
  },
  bookService:{label:"",value:"", Route: []},
  searchBookingEntity: "",
  creditNumberLoading: false,
  resetPackageErrors: false,
  bookingUpdate: {
    packages: false,
    destCity: false,
    payment: false,
  },
  material: "",
  purpose: { label: "Commercial", value: "C" },
  goods: "",
  fedexServiceType: "",
  fedexServiceTypeOptions: [],
  pickupContact: "",
  contactData : null,
  remarks: "",
  cancelReason: "",
  userName: null,
  contact: null,
  driverName: null,
  from: null,
  to: null,
  assign: {
    branches: null,
    departments: null,
    fleets: null,
  },
  ppf: false,
  kgw: false,
  fr_ppf: false,
  fr_kgw: false,
  editBranchId: null,
  routeSugg: null,
  fr: 0,
  grossFr:0,
  totalWeight:0,
  pickupCharge: 0,
  deliveryCharge: 0,
  editDocket: false,
  deliveryType: { value: "Branch To Branch", label: "Branch To Branch" },
  bf: 0,
  hf: 0,
  dc: 0,
  gross: 0,
  packages: [
    {
      id: 0,
      qty: "",
      materialType: { label: "", value: "" },
      packingType: { label: "", value: "" },
      size: { label: "custom", value: "custom" },
      dimension: { l: "", b: "", h: "" },
      weight: "",
      rate: { value: "Per package", label: "Per package" },

      unit: "",
      amount: "",
      stack: false,
      haz: false,
      frag: false,
      status : ""
    },
  ],
  // transportCharges:[],// this field for store transport charge company wise
  fetchDocketUpdate: [],
  risk: 0,
  imageGalleryArr: [],
  valueCharge: 0,
  ins: 0,
  isCol: false,
  howcollect: "",
  colMonth: 0,
  customPack: "null",
  bookingRate: "Direct",
  credit: initCredit,
  assignroute: "",
  bookdestbranch: {
    label: "",
    value: "",
    company: { _id: "" },
    distance: "",
  },
  actDestBranch: {
    label: "",
    value: "",
    company: { _id: "" },
    distance: "",
  },
  isEwayBill: false,
  sender: {
    name: "",
    contact: "",
    gst: "",
    l1: "",
    l2: "",
    pin: "",
    city: "",
    otherAddr: [],
    gstNos: undefined,
    names: undefined,
    senderCode: "",
    ewayBillSenderId : "",
    ewayBillSenderName : "",
    ewayBillSenderContact : "",
    isSenderDisable: false,
    contactVerified: false,
  },
  receiver: {
    name: "",
    contact: "",
    gst: "",
    l1: "",
    l2: "",
    pin: "",
    city: "",
    otherAddr: [],
    gstNos: undefined,
    names: undefined,
    receiverCode: "",
    ewayBillReceiverId : "",
    ewayBillReceiverName : "",
    ewayBillReceiverContact : "",
    isReceiverDisable: false,
  },
  fixRateCustomer:{
    name:"",
    contact:"",
    customerType:""
  },
  newBranches: [],
  creditNumber: "",
  colReport,
  delAmt: "",
  specificDelivery: false,
  handlingChargeSlab: [],
  deliveryChargesSlab: [],
  builtyChargeSlab: [],
  multipleEwayBillData: [],
  senderRecieverEwaySet: {from : "" , ind : null},
  multipleEwayBillCount: 0,
  sizes: [],
  bookTouches: IMap(),
  bookErrors: IMap(),
  orangeLR: null,
  bookingMode: {
    label: "ToPay",
    value: "topay",
  },
  nonCashPaymentType: "",
  nonCashTxnId: "",
  nonCashTxnImage: "",
  nonCashTxnsNumber:"",
  bookingCredit: initCredit,
  senderAddressLock: false,
  receiverAddressLock: false,
  focOTP: "",
  focContact: "",
  isFocOTPSent: false,
  senderOTP: "",
  isSenderNew: false,
  isSenderOTPSent: false,
  isSenderVerificationDialog: false,
  isResendDisabled: false,
  senderType: "",
  employeeContact: "",
  //isEmployeeContactValidate:false,
  isEmployeeVerificationRequired: true,
  employeeOTP: "",
  isEmployeeOTPSent: false,
  isEmployeeVerificationDialog: false,
  isEmployeeNew: false,
  isEmployeeFetched: false,
  employeeName: "",
  individualFleet:{
  name:{
    fName:"",
    lName:""
  },
  regNumber:"",
  otp:""
  },
  doorDelivery: {
    deliveryCharge:0,
    origin: {},
    l1: "",
    l2: "",
    officeName: "",
    floor: "",
    tower: "",
    nearby_landmark: "",
    doorDeliveryPaymentMode: "",
    pincode: "",
    city: "",
    area: "",
    state: "",
    shouldDoorDeliveryBook: false,
    serviceType: "",
    assetType:"",
    delCity:""
  },
  lastBookingDetail:{
    
  },
  senderForceNameRemoveDueToGst: false,
  receiverForceNameRemoveDueToGst: false,
  senderGstRelativeFieldDisable: false,
  receiverGstRelativeFieldDisable: false,

  senderGstRemoveReqData: {
    dialog: false,
    step: 1,
    gstData: [],
    newGst: "",
    removedGst: "",
    primaryContact: ""
  },
  receiverGstRemoveReqData: {
    dialog: false,
    step: 1,
    gstData: [],
    newGst: "",
    removedGst: "",
    primaryContact: ""
  },

  senderSecondaryContactReqData: {
    dialog: false,
    step: 1,
    secondaryContactData: [],
    newContact: "",
    removedContact: "",
    primaryContact: ""
  },
  receiverSecondaryContactReqData: {
    dialog: false,
    step: 1,
    secondaryContactData: [],
    newContact: "",
    removedContact: "",
    primaryContact: ""
  },
  senderGstContacts: [],
  receiverGstContacts: [],
  generalWarning: ""
};

const reducer = (state: any = initState, action: any) => {
  try {
    switch (action.type) {
      case BOOK_BILL: {
        return { ...state, bill: action.payload };
      }
      case BOOK_PURPOSE: {
        return {
          ...state,
          purpose: action.payload,
          ewb: "",
          bill: "",
          bookTouches: state.bookTouches.setIn(["purpose"], true),
        };
      }
      case BOOK_DOCKET: {
        return { ...state, searchBookingEntity: action.payload };
      }
      case BOOK_EWB: {
        return { ...state, ewb: action.payload };
      }
      case SET_PARTICULAR_EWB: {
        return { ...state, multipleEwayBillData: action.payload };
      }
      // case SET_MULTIPLE_EWAYBILL_DATA: {
      //   console.log(`SET_MULTIPLE_EWAYBILL_DATA reducer`,action?.payload , state?.multipleEwayBillData)
      //   let [data , changeIn , eWaybillNo] = action?.payload;
      //   let finalAns : any = [];
      //   let changableObj = state?.multipleEwayBillData?.map((x:any , index : any) => {
      //     if(x?.index === changeIn){
      //       finalAns?.push({
      //         index : x?.index,
      //         ewb : eWaybillNo,
      //         bill : data?.document_number,
      //         goods : data?.total_invoice_value, 
      //         isEwayBill: true, 
      //       })
      //     }
      //     else if(x?.index !== changeIn){
      //       finalAns?.push(x);
      //     }
      //     else if (state?.multipleEwayBillData?.length >= index+1){
      //       return;
      //     }
      //   });
      //   console.log(`SET_MULTIPLE_EWAYBILL_DATA changeIn 520`,changableObj , `data` , data , `finalAns` , finalAns)
      //   return {
      //     ...state,
           
      //   };
      // }
      case SET_MULTIPLE_EWAYBILL_DATA: {
        console.log(`SET_MULTIPLE_EWAYBILL_DATA reducer`, action?.payload , `sender checking` , action?.srData , `state` , state?.sender , state?.receiver);
        if(state?.sender?.name !== "" && state?.sender?.gst !== "" && state?.receiver?.name !== "" && state?.receiver?.gst !== "" &&
          action?.srData?.arr?.gstin_of_consignor !== state?.sender?.gst?.value && action?.srData?.arr?.gstin_of_consignee !== state?.receiver?.gst?.value){
            showMessage(`Two Different Sender And Reciever's E-way Bill Not Allowed`, failed , 5000);
            return {...state}; 
        }
        if(action?.srData && action?.srData?.check){
          const data = Object.assign({}, action?.srData?.arr);
          const sender: any = {
            ...state.sender,
            name: toRSOption(data.legal_name_of_consignor),
            gst: toRSOption(data.gstin_of_consignor),
            ewayBillSenderId : data?.sender?._id,
            ewayBillSenderName : data?.sender?.name,
            ewayBillSenderContact : data?.sender?.contact,
            
          };
          const receiver: any = {
            ...state.receiver,
            name: toRSOption(data.legal_name_of_consignee),
            gst: toRSOption(data.gstin_of_consignee),
            ewayBillReceiverId : data?.reciever?._id,
            ewayBillReceiverName : data?.reciever?.name,
            ewayBillReceiverContact : data?.reciever?.contact,
          };
          console.log(`SET_MULTIPLE_EWAYBILL_DATA reducer`, action, state?.multipleEwayBillData);
          return {
            ...state,
            multipleEwayBillData: action?.payload,
            sender,
            receiver,
            senderRecieverEwaySet : {from : "additionalEwayBills" , ind : action?.srData?.indexValue},
          };
        }
        else{
          return {
            ...state,
            multipleEwayBillData: action?.payload,
            senderRecieverEwaySet : {from : "" , ind : null},
          };
        }
      }      
      case RESET_PARTICULAR_EWAYBILL_DATA: {
        console.log(`RESET_PARTICULAR_EWAYBILL_DATA reducer`, action?.payload, state?.senderRecieverEwaySet);
        return {
          ...state,
          multipleEwayBillData: action?.payload,
        };
      }      
      case CHANGE_MULTIPLE_EWAYBILL_MANUAL_DATA: {
        console.log(`CHANGE_MULTIPLE_EWAYBILL_MANUAL_DATA reducer`, action?.payload, state?.multipleEwayBillData);
        return {
          ...state,
          multipleEwayBillData: action?.payload,
        };
      }      
      case ADD_MULTIPLE_EWB: {
        console.log(`ADD_MULTIPLE_EWB reducer`,action.payload)
        return {
          ...state,
           multipleEwayBillData  : [
            ...state?.multipleEwayBillData,
            {
            index : action.payload,
            ewb : '',
            bill : '',
            goods : '',
            ins : '',
            col : '', 
          }],
          multipleEwayBillCount : state?.multipleEwayBillCount + 1,
        };
      }
      case REMOVE_MULTIPLE_EWB: {
        console.log(`REMOVE_MULTIPLE_EWB reducer`,action.payload ,  `senderRecieverEwaySet` , state?.senderRecieverEwaySet)
        if(action?.payload === state?.senderRecieverEwaySet?.ind && state?.senderRecieverEwaySet?.from === "additionalEwayBills"){
          return {
            ...state,
             multipleEwayBillData : state?.multipleEwayBillData?.filter((x:any) => x?.index !== action.payload),
             multipleEwayBillCount : state?.multipleEwayBillCount - 1,
             sender: {
               name: "",
               contact: "",
               gst: "",
               l1: "",
               l2: "",
               pin: "",
               city: "",
               otherAddr: [],
               gstNos: undefined,
               names: undefined,
               senderCode: "",
               ewayBillSenderId : "",
               ewayBillSenderName : "",
               ewayBillSenderContact : "",
               isSenderDisable: false,
               contactVerified: false,
             },
             receiver: {
               name: "",
               contact: "",
               gst: "",
               l1: "",
               l2: "",
               pin: "",
               city: "",
               otherAddr: [],
               gstNos: undefined,
               names: undefined,
               receiverCode: "",
               ewayBillReceiverId : "",
               ewayBillReceiverName : "",
               ewayBillReceiverContact : "",
               isReceiverDisable: false,
             },  
          };
        }
        else{
          return {
            ...state,
             multipleEwayBillData : state?.multipleEwayBillData?.filter((x:any) => x?.index !== action.payload),
             multipleEwayBillCount : state?.multipleEwayBillCount - 1,
          };
        }
      }
      case RESET_MULTIPLE_EWB: {
        console.log(`RESET_MULTIPLE_EWB reducer`)
        return {
          ...state,
           multipleEwayBillData : [],
           multipleEwayBillCount : 0,
        };
      }
      case BOOK_SENDER: {
        const { receiver } = state;
        const sender = Object.assign({}, state.sender);
        const { what, val } = action.payload;
        sender[what] = val;
        let senderGst = false;
        let senderForceNameRemoveDueToGst = false;
        if (
          what === "contact" &&
          receiver &&
          receiver.contact &&
          receiver.contact === val
        ) {
          toast.error("Sender Contact Cannot Be same as Receiver Contact");
          return state;
        }
        if (what === "gst") {
          const { gstNos } = sender;
          if (gstNos && isArrayCheck(gstNos) && val && val.value) {
            const gstConcern = gstNos.find((g: any) => g.GSTIN === val.value);
            console.log("gst concern : ", gstConcern);
            const checkIsGstNameExists = sender?.names?.find((name:any) => gstConcern?.name?.toUpperCase() === name?.toUpperCase())
            senderForceNameRemoveDueToGst = sender?.names?.length >= 3 && !checkIsGstNameExists

            if (gstConcern) {
              sender.name = {
                label: gstConcern.name,
                value: gstConcern.name,
              };
              senderGst = true;
            }
          }
          else if(val !== undefined){
            console.log("gst concern :  " , val);
            senderGst = true;
          }
          else if(val === undefined){
            console.log("gst concern :  " , val);
            senderGst = false;
          }
          if (val && val.value) {
            sender[what] = {
              label: val.label.toUpperCase(),
              value: val.value.toUpperCase(),
            };
          } else {
            sender[what] = null;
          }
        }
        return {
          ...state,
          sender: sender,
          bookTouches: state.bookTouches.setIn(["sender", what], true),
          senderGst,
          senderForceNameRemoveDueToGst,
          ...(senderForceNameRemoveDueToGst ? {senderGstRelativeFieldDisable: true} : {})
        };
      }
      case TOUCH_SENDER: {
        return {
          ...state,
          bookTouches: state.bookTouches.setIn(
            ["sender", action.payload],
            true
          ),
        };
      }
      case BOOK_SET_SENDER: {
        const {
            _id,
            address: serverAddress,
            gst,
            otherAddr,
            name,
            gstNos,
            contact,
            names,
            contactVerified,
            image,
            isContactValidated,
            customerType,
            connectedContacts
          } = action.payload,
          { pincode } = serverAddress;
        const address = { ...serverAddress, pin: pincode };
        console.log("sender  address", action.payload);
        let newOthr: any[] = [],
          senderData: any;
        if (isArrayCheck(otherAddr) && address) {
          newOthr = otherAddr.map((a: any) => ({ ...a, pin: a.pincode }));
        }

        const onlyPrimaryGST = customerType === 'P' ? gstNos : connectedContacts?.gstNos
        newOthr.push(address);
        if (state.sender.gst) {
          senderData = {
            contact: contact,
            otherAddr: newOthr,
            image,
            ...address,
          };
        } else {
          senderData = {
            contact: contact,
            gst,
            otherAddr: newOthr,
            image,
            ...address,
          };
        }

        if (!state.isEwayBill) {
          senderData.name = { value: name, label: name };
        }
        // if (state.sender.name != "") delete senderData.name;

        return {
          ...state,
          sender: {
            _id,
            ...state.sender,
            ...senderData,
            gstNos: isArrayCheck(onlyPrimaryGST)
              ? onlyPrimaryGST.filter(
                  (g: any) =>
                    g.GSTIN && g.GSTIN.length > 0 && g.name && g.name.length > 0
                )
              : [],
            names: names,
            contactVerified: contactVerified?contactVerified:false,
            isContactValidated:isContactValidated?isContactValidated:false,
            customerType,
            connectedContacts
          },
        };
      }
      case BOOK_SET_SENDER_VALIDATE: {
        const {
          isContactValidated,
          message
        } = action.payload
        //console.log("sender", action.payload);
        if(message){
          showMessage(message, success);
        }
        return {
          ...state,
          sender: {
            ...state.sender,
            isContactValidated
          },
        };
      }
      case BOOK_SET_RECEIVER_VALIDATE: {
        const {
          isContactValidated,
          message
        } = action.payload
        console.log("receiver", action.payload);
        if(message){
          showMessage(message, success);
        }
        return {
          ...state,
          receiver: {
            ...state.receiver,
           isContactValidated
          },
        };
      }
      case BOOK_RESET_SENDER: {
        return {
          ...state,
          sender: {
            ...initState.sender,
          },
        };
      }
      case SET_RECEIVER_SUGGESTIONS: {
        return {
          ...state,
          receiverSuggestions: action.payload,
        };
      }
      case BOOK_SET_FIXRATECUSTOMER:{
        const {name,contact,customerType}=action.payload
        console.log("namejjjjjj :",name)
        return {
          ...state,
          fixRateCustomer:{
            name,
            contact,
            customerType
          },
        };
      }
      case BOOK_RESET_FIXRATECUSTOMER:{
        return {
          ...state,
          fixRateCustomer:{
            ...initState.fixRateCustomer
          },
        };
      }
      case "SET_MATERIAL": {
        const { bookingUpdate } = state;
        return {
          ...state,
          material: action.payload,
          bookingUpdate: {
            ...bookingUpdate,
            packages: true,
          },
        };
      }
      case "SET_PACKING_TYPE": {
        const { bookingUpdate } = state;
        return {
          ...state,
          packingType: action.payload,
          bookingUpdate: {
            ...bookingUpdate,
            packages: true,
          },
        };
      }
      case SET_RATE_SUGGESTIONS: {
        return {
          ...state,
          rateSuggestions: action.payload,
        };
      }
      case SET_PACKAGE_SUGGESTIONS: {
        return {
          ...state,
          packageSuggestions: action.payload,
        };
      }
      case BOOK_SET_RECEIVER: {
        const {
            _id,
            address: serverAddress,
            gst,
            otherAddr,
            name,
            gstNos,
            contact,
            names,
            contactVerified,
            isContactValidated,
            customerType,
            connectedContacts
          } = action.payload,
          { pincode } = serverAddress;

        const address = { ...serverAddress, pin: pincode };
        let newOthr: any[] = [],
          receiverData: any;
        if (isArrayCheck(otherAddr) && address) {
          newOthr = otherAddr.map((a: any) => ({ ...a, pin: a.pincode }));
        }
        newOthr.push(address);
        console.log("r address", newOthr);
        if (state.receiver.gst) {
          receiverData = {
            otherAddr: newOthr,
            contact: contact,
          };
        } else {
          console.log("inelse");
          receiverData = {
            gst,
            otherAddr: newOthr,
            contact: contact,
          };
        }

        if (!state.isEwayBill) {
          receiverData.name = { value: name, label: name };
        }
        // if (state.receiver.name != "") delete receiverData.name;

        const onlyPrimaryGST = customerType === 'P' ? gstNos : connectedContacts?.gstNos
        return {
          ...state,
          receiver: {
            _id,
            ...state.receiver,
            ...receiverData,
            gstNos: onlyPrimaryGST,
            names: names,
            isContactValidated:isContactValidated?isContactValidated:false,
            contactVerified
          },
        };
      }
      case TOUCH_RECEIVER: {
        return {
          ...state,
          bookTouches: state.bookTouches.setIn(
            ["receiver", action.payload],
            true
          ),
        };
      }
      case BOOK_RESET_RECEIVER: {
        return {
          ...state,
          receiver: {
            ...state.receiver,
            pin: state.receiver.pin,
            isReceiverDisable: false,
            name: "",
            contact: "",
            gst: "",
            contactVerified: false,
            isContactValidated:false

          },
        };
      }
      case BOOK_SET_IS_SENDER_DISABLE: {
        return {
          ...state,
          sender: {
            ...state.sender,
            isSenderDisable: action.payload,
          },
        };
      }
      case BOOK_SET_FOC_VERIFICATION: {
        const { what, val } = action.payload;
        if (what) {
          return {
            ...state,
            [what]: val,
          };
        } else {
          return {
            ...state,
            focOTP: "",
            focContact: "",
            isFocOTPSent: false,
          };
        }
      }
      case BOOK_SET_SENDER_VERIFICATION: {
        const { what, val } = action.payload;

        if (what) {
          return {
            ...state,
            [what]: val,
          };
        } else {
          return {
            ...state,
            senderOTP: "",
            isSenderNew: false,
            isSenderOTPSent: false,
            isSenderVerificationDialog: false,
            isContactValidated:false,
            contactVerified:false,
          };
        }
      }
      case BOOK_SET_EMPLOYEE_VERIFICATION: {
        const { what, val } = action.payload;

        if (what) {
          return {
            ...state,
            [what]: val,
          };
        } else {
          return {
            ...state,
            isEmployeeVerificationRequired: true,
            employeeOTP: "",
            isEmployeeOTPSent: "",
            isEmployeeVerificationDialog: false,
            employeeContact: "",
            employeeName: "",
            isEmployeeNew: false,
            isEmployeeFetched: false,
          };
        }
      }
      case BOOK_SET_INDIVIDUALFLEET:{
        const { what, val } = action.payload;
       return {
        ...state,
        individualFleet:{
          ...state.individualFleet,
          [what]:val
        }
       }
      }
      case BOOK_SET_VERIFICATION: {
        const { what, val } = action.payload;

        if (what) {
          return {
            ...state,
            [what]: val,
          };
        } else {
          return {
            // ...state,
            // senderOTP: "",
            // isSenderNew: false,
            // isSenderOTPSent: false,
            // isSenderVerificationDialog: false,
          };
        }
      }
      case SET_DOOR_DELIVERY: {
        const { what, val } = action.payload;

      
          return {
            ...state,
            doorDelivery: {
              ...state.doorDelivery,
              [what]: val,
            },
        }
      }
      case SET_DOOR_DELIVERY_DATA: {
        
          return {
            ...state,
            doorDelivery: {
              ...state.doorDelivery,
             ...action.payload,
            },
        }
      }
      case RESET_DOOR_DELIVERY: {

          return {
            ...state,
            doorDelivery: {
              deliveryCharge:0,
              origin: {},
              l1: "",
              l2: "",
              officeName: "",
              floor: "",
              tower: "",
              nearby_landmark: "",
              doorDeliveryPaymentMode: "",
              pincode: "",
              city: "",
              area: "",
              state: "",
              shouldDoorDeliveryBook: false,
              serviceType: ""
            },
          };
        
      }
      case BOOK_SET_IS_RECEIVER_DISABLE: {
        return {
          ...state,
          receiver: {
            ...state.receiver,
            isReceiverDisable: action.payload,
          },
        };
      }
      case BOOK_RECEIVER: {
        const { sender } = state;
        const receiver = Object.assign({}, state.receiver);
        let receiverGst = false;
        const { what, val } = action.payload;
        receiver[what] = val;
        if (
          what === "contact" &&
          sender &&
          sender.contact &&
          sender.contact === Number(val)
        ) {
          toast.error("Sender Contact Cannot Be same as Receiver Contact");
          return state;
        }

        let receiverForceNameRemoveDueToGst = false
        if (what === "gst") {
          if (val && val.value) {
            const { gstNos } = receiver;
            if (gstNos && isArrayCheck(gstNos) && val && val.value) {
              const gstConcern = gstNos.find((g: any) => g.GSTIN == val.value);
              console.log("gst concern : ", gstConcern);
              const checkIsGstNameExists = receiver?.names?.find((name:any) => gstConcern?.name?.toUpperCase() === name?.toUpperCase())
              receiverForceNameRemoveDueToGst = receiver?.names?.length >= 3 && !checkIsGstNameExists
              if (gstConcern) {
                receiver.name = {
                  label: gstConcern.name,
                  value: gstConcern.name,
                };
                receiverGst = true;
              }
            }
            else if(val !== undefined){
              console.log("gst concern :  " , val);
              receiverGst = true;
            }
            else if(val === undefined){
              console.log("gst concern :  " , val);
              receiverGst = false;
            }
            
            receiver[what] = {
              label: val.label.toUpperCase(),
              value: val.value.toUpperCase(),
            };
          } else {
            receiver[what] = null;
          }
        }
        return {
          ...state,
          receiver,
          bookTouches: state.bookTouches.setIn(["receiver", what], true),
          receiverGst,
          receiverForceNameRemoveDueToGst,
          ...(receiverForceNameRemoveDueToGst ? {receiverGstRelativeFieldDisable: true} : {})
        };
      }
      case SET_DOCKET: {
        
        const {
          docket,
          packages,
          billUrl,
          idUrl,
          fedexLabels,
          docketUrls,
          delivery,
          consigneeIdImage,
          podImage,
          allcities,
          imageGallery 
        } = action.payload;
        // for older fedex bookings wihtout dest branch
        if (!docket.destBranch) {
          docket.destBranch = docket.originBranch;
        }
        console.log("docket", docket);
        const {
          purpose,
          originBranch,
          eWayBill,
          billNo,
          paymentMode,
          senderName,
          receiverName,
          senderGst: senderGST,
          receiverGst: receiverGST,
          franchiseNumber,
          createdAt,
          // remark,
          docketNumber,
          remarks,
          // cancelReason,
          trackingNumber,
          destBranch,
          gross,
          ewayGoods,
          ewayBillNo,
          eWayBills,
          intermediateCharge,
        } = docket;
        const purposes: {
          [k: string]: { label: string; value: string };
        } = {
          P: { label: "Personal", value: "P" },
          C: { label: "Commercial", value: "C" },
        };

        const sender: any = {
            name: toRSOption(senderName),
            contact: docket.sender.contact,
            gst: toRSOption(senderGST),
            gstNos: docket.sender.gstNos,
            names: docket.sender.names,
          },
          receiver: any = {
            name: toRSOption(receiverName),
            contact: docket.receiver.contact,
            gst: toRSOption(receiverGST),
            gstNos: docket.receiver.gstNos,
            names: docket.receiver.names,
          };
        // orgBranch,
        // findCity,
        // originCity,
        // remarks = docket.remarks,
        let senderGst = false,
          receiverGst = false;
        if (docket.sender.gst && docket.purpose != "P") {
          senderGst = true;
        }
        if (docket.receiver.gst && docket.purpose != "P") {
          receiverGst = true;
        }
        const orgBranch = {
          label: originBranch.branchName,
          value: originBranch._id,
        };
        const findCity = allcities.find(
          (c: any) => c._id == originBranch.address.city._id
        );
        console.log("findCity", findCity);
        const originCity = { label: findCity.name, value: findCity.name };

        const findDestCity = allcities.find(
          (c: any) => c._id == destBranch.address.city._id
        );

        const destCity = { label: findDestCity.name, value: findDestCity._id };

        const uniquePackageMap: any = {};
        packages.forEach((p: any) => {
          const {
            materialType,
            packingType,
            size,
            dimension,
            weight,
            rate,
            amount,
            unit,
            stack,
            haz,
            frag,
            status,
            extraGrossFreight
          } = p;

          const { l, b, h } = dimension;
          const uid = [
            materialType && materialType.name ? materialType.name : "N/A",
            materialType && materialType._id ? materialType._id : "N/A",
            packingType && packingType.name ? packingType.name : "N/A",
            packingType && packingType._id ? packingType._id : "N/A",
            size,
            l,
            b,
            h,
            unit,
            weight,
            rate,
            amount + (extraGrossFreight || 0),
            stack,
            haz,
            frag,
            status
          ].join("@");
          if (uid in uniquePackageMap) {
            uniquePackageMap[uid] += 1;
          } else {
            uniquePackageMap[uid] = 1;
          }
        });
        const tempPackages = Object.keys(uniquePackageMap).map((uid, idx) => {
          const [
            materialType_name,
            materialType_id,
            packingType_name,
            packingType_id,
            size,
            l,
            b,
            h,
            unit,
            weight,
            rate,
            amount,
            stack,
            haz,
            frag,
            status
          ] = uid.split("@");

          const qty = uniquePackageMap[uid];
          const freightRates: {
            [k: string]: { label: string; value: string };
          } = {
            Direct: { label: "Direct", value: "Direct" },
            "Per package": { label: "Per package", value: "Per package" },
            "Per Kg": { label: "Per Kg", value: "Per Kg" },
          };
          const units: {
            [k: string]: { label: string; value: string };
          } = {
            m: { label: "Mt", value: "m" },
            cm: { label: "Cm", value: "cm" },
            in: { label: "Inch", value: "in" },
          };
          const sizeArr = state.sizes,
            sizeMap: any = {};
          if (isArrayCheck(sizeArr)) {
            sizeArr.forEach((s: { name: string }) => {
              sizeMap[s.name] = { label: s.name, value: s.name };
            });
          }
          return {
            qty,
            materialType: {
              label: materialType_name,
              value: materialType_id,
            },
            packingType: {
              label: packingType_name,
              value: packingType_id,
            },
            size: sizeMap[size],
            dimension: { l, b, h },
            weight,
            rate: freightRates[rate],
            amount: parseInt(amount), //rate == 'Per package' ? parseInt(amount) : parseInt(amount, 10) * parseInt(qty, 10),
            unit: units[unit],
            stack: stack == "true",
            haz: haz == "true",
            frag: frag == "true",
            id: idx,
            status : status
          };
        });
        const paymentModeMap: {
          [k: string]: { label: string; value: string };
        } = {
          paid: { label: "Paid", value: "paid" },
          topay: { label: "ToPay", value: "topay" },
          credit: { label: "Credit", value: "credit" },
          foc: { label: "FOC", value: "foc" },
          noncash: { label: "Non Cash", value: "noncash" },
        };

        const orangeLR = docket.isOrangeBooking
          ? docket.orangeDetails.lrNumber
          : null;
        const otherValues = {
          hf: docket.handling,
          bf: docket.bilty,
          dc: docket.Delivery,
          orangeLR,
          senderGst,
          receiverGst,
          pickupCharge: docket.pickupCharge,
          deliveryCharge: docket.deliveryCharge,
          fr: docket.freight,
          risk: docket.risk,
          ins: docket.insAmt,
          valueCharge: docket.valueCharge,
          isCol: docket.colEnabled,
          howcollect: docket.collectionType,
          col: docket.colAmt,
          colValue: docket.colFee,
          packValue: docket.packFee,
          colMonth: docket.colMonth,
          customPack: docket.customPack,
          // bookingRate: docket.freightType,
          bookingRate: docket.freightType,
          bookingMode: !delivery ? paymentModeMap[paymentMode] : paymentMode,
          goods: docket.goods,
          purpose: purposes[purpose],
          docketStatus: docket.status,
          franchiseNumber,
          remark: docket.remarks,
          specificDelivery: docket.delSpecific,
          isEwayBill: eWayBill && eWayBill.length > 0,
          bookingTime: createdAt,
          fromAddr: docket.originBranch ? docket.originBranch.branchName : "",
          toAddr: docket.destBranch ? docket.destBranch.branchName : "",
          gross,
          intermediateCharge,
          ewayGoods : ewayGoods,
          ewayBillNo : ewayBillNo,
          eWayBills : eWayBills,
          imageGalleryArr : imageGallery
        };
        console.log("otherValues: ", otherValues);
        // const bookdestcity = allcities
          // ? docket.isFedexBooking
          //   ? allcities.filter((c: any) => c._id == docket.destBranch.address.city._id)[0]
          //   : allcities.filter((c: any) => c._id == packageSample.destCity)[0]
          // : null;
        const bookdestbranch = docket.destBranch;
        const actDestBranch = docket.actDestBranch;

        const delDocket = {
          docket: {
            ...docket,
            paymentMode: paymentModeMap[docket.paymentMode],
            consigneeIdImage,
            podImage,
          },
          packages,
        };

        return {
          ...state,
          docketNumber,
          trackingNumber,
          fedexLabels,
          sender,
          receiver,
          bill: billNo,
          bookingStatus: docket.status,
          ewb: eWayBill,
          packages: tempPackages,
          oldPackages: tempPackages,
          fetchDocketUpdate: [],
          delDocket,
          editDocket: true,
          idUrl,
          orgBranch,
          remarks,
          originCity,
          idFetch: typeof idUrl === "string" && idUrl.length > 0 ? true : false,
          billUrl,
          billFetch:
            typeof billUrl === "string" && billUrl.length > 0 ? true : false,
          docketUrls,
          docketsFetch:
            Array.isArray(docketUrls) && docketUrls.length > 0 ? true : false,
          bookingUpdate: {
            ...state.bookingUpdate,
            payment: true,
            packages: true,
            destCity: true,
          },
          bookdestcity: destCity,
          bookdestbranch: bookdestbranch
            ? { label: bookdestbranch.branchName, value: bookdestbranch._id }
            : null,
            actDestBranch: actDestBranch
            ? { label: actDestBranch.branchName, value: actDestBranch._id }
            : null,
          ...otherValues,
        };
      }
      case UPDATE_BUILTY: {
        return { ...state, updateBuilty: true };
      }
      case SET_DOCKET_FROM_EWAYBILL: {
        const data = Object.assign({}, action.payload);

        delete state?.sender?.contact
        const sender: any = {
          ...state.sender,
          name: toRSOption(data.legal_name_of_consignor),
          gst: toRSOption(data.gstin_of_consignor),
          ewayBillSenderId : data?.sender?._id,
          ewayBillSenderName : data?.sender?.name,
          // ewayBillSenderContact : data?.sender?.contact,
          ewayBillAddress : {
            l1 : data?.address1_of_consignor,
            l2 : data?.address2_of_consignor,
            city : data?.place_of_consignor, 
            pincode : data?.pincode_of_consignor, 
            state : data?.state_of_consignor, 
          }
        };

        delete state?.reciever?.contact
        const receiver: any = {
          ...state.receiver,
          name: toRSOption(data.legal_name_of_consignee),
          gst: toRSOption(data.gstin_of_consignee),
          ewayBillReceiverId : data?.reciever?._id,
          ewayBillReceiverName : data?.reciever?.name,
          // ewayBillReceiverContact : data?.reciever?.contact,
          ewayBillAddress : {
            l1 : data?.address1_of_consignee,
            l2 : data?.address2_of_consignee,
            city : data?.place_of_consignee, 
            pincode : data?.pincode_of_consignee, 
            state : data?.state_of_supply, 
          }
        };
        const bill = data.document_number;
        const goods = data.total_invoice_value;
        return {
          ...state,
          sender,
          receiver,
          bill,
          goods,
          isEwayBill: true,
        };
      }
      case RESET_EWAYBILL_DATA: {
  
        const sender: any = {
          ...initState.sender,
        };
        const receiver: any = {
          ...initState.receiver,
        };
        const bill = "";
        const goods = initState.goods
        return {
          ...state,
          sender,
          receiver,
          bill,
          goods,
          isEwayBill: false,
        };
      }
      case RESET_SENDER_NAME: {
        const sender = {
          ...state.sender,
          name: "",
        };
        return {
          ...state,
          sender,
          senderGst: false,
        };
      }
      case RESET_REMARKS: {
        return {
          ...state,
          remarks: "",
        };
      }
      case RESET_RECEIVER_NAME: {
        const receiver = {
          ...state.receiver,
          name: "",
          names: [],
        };
        return {
          ...state,
          receiver,
          receiverGst: false,
        };
      }
      case REFRESH_BOOKING: {
        return {
          ...state,
          bookingUpdate: {
            ...state.bookingUpdate,
            payment: true,
            packages: true,
            destCity: true,
          },
          senderGstInvalid: false,
          senderForceNameRemoveDueToGst: false,
          receiverForceNameRemoveDueToGst: false,
          senderGstRelativeFieldDisable: false,
          receiverGstRelativeFieldDisable: false,        
          receiverGstInvalid: false,
        };
      }
      case GET_BRANCHES_BY_COMPANY: {
        return {
          ...state,
          newBranches: action.payload,
        };
      }
      case BOOK_COL_MONTH: {
        return { ...state, colMonth: parseInt(action.payload, 10) };
      }
      // case BOOK_RATE: {
      //   return { ...state, bookingRate: action.payload };
      // }
      case BOOK_PACK: {
        try {
          const val = action.payload;
          let charge = 0;
          state.company.packaging.forEach((slab: any) => {
            if (slab.name == val) {
              charge = slab.price;
            }
          });
          return {
            ...state,
            customPack: action.payload,
            packValue: charge,
          };
        } catch (err : any) {
          console.log(err);
          return state;
        }
      }
      case BOOK_HOW_COL: {
        try {
          if (!state.goods || state.goods.length == 0) throw "GOODS_NULL";
          const newCol = parseInt(String(state.col), 10);
          const arr = action.payload.companyData[action.payload.data];

          let colCharge = 0;
          if (arr && arr.length > 0) {
            arr.forEach((ele: any) => {
              if (newCol >= ele.min && newCol <= ele.max) {
                colCharge = ele["charge"];
              }
            });
          } else if (action.payload.data == "") {
            return {
              ...state,
              howcollect: action.payload.data,
              colValue: 0,
              col: 0,
            };
          }
          return {
            ...state,
            howcollect: action.payload.data,
            colValue: colCharge,
          };
        } catch (err : any) {
          if (err == "GOODS_NULL") {
            showMessage("Insert goods value first", failed);
          }
          console.log(err);
          return state;
        }
      }
      case PICKUP_CHARGE_CHANGE: {
        const { what, val } = action.payload;
        return {
          ...state,
          [what]: val,
        };
      }
      case BOOK_RISK_CHANGE: {
        try {
          console.log("riskData", action.payload);
          if (!state.goods || state.goods.length == 0) throw "GOODS_NULL";
          const flag = action.payload.risk;
          let arr = [];
          if (flag == 0) {
            return { ...state, risk: action.payload.risk, valueCharge: 0 };
          }
          if (flag == 1) {
            arr = action.payload.companyData.insurance.owner;
          }
          if (flag == 2) {
            arr = action.payload.companyData.insurance.carrier;
          }

          const newIns = parseInt(String(state.ins), 10);
          let insCharge = 0;
          arr.forEach((ele: any) => {
            if (newIns >= ele.min && newIns <= ele.max) {
              insCharge = ele["charge"];
            }
          });
          return {
            ...state,
            ins: newIns,
            valueCharge: insCharge,
            risk: action.payload.risk,
          };
        } catch (err : any) {
          console.log(err);
          if (err == "GREATER")
            showMessage(
              "Insurance base value must be less than goods value",
              failed
            );
          else if (err == "GOODS_NULL")
            showMessage("Set goods value first", failed);
          return state;
        }
      }
      case BOOK_REMARKS_CHANGE: {
        return {
          ...state,
          remarks: action.payload,
        };
      }
      case BOOK_PICKUP_CONTACT: {
        return {
          ...state,
          pickupContact: action.payload,
        };
      }
      case BOOK_CONTACT_DATA: {
        return {
          ...state,
          contactData: action.payload,
        };
      }
      
      case BOOK_GOODS_CHANGE: {
        try {
          let insCharge = 0;

          const val = parseInt(action.payload, 10);
          //calculate insurance
          if (state.risk != 0) {
            let arr = [];
            if (state.risk == 1) arr = state.company.insurance.owner;
            if (state.risk == 2) arr = state.company.insurance.carrier;
            arr.forEach((ele: any) => {
              if (val >= ele.min && val <= ele.max) {
                insCharge = ele["charge"];
              }
            });
          }
          let colCharge = 0;
          console.log("state.isCol: ", state.isCol);
          if (state.isCol) {
            let arr = [];
            if (state.howcollect == "cod") {
              arr = state.company.cod;
            } else if (state.howcollect == "col") {
              arr = state.company.col;
            }

            if (state.howcollect == "cod" || state.howcollect == "col") {
              arr.forEach((ele: any) => {
                if (val >= ele.min && val <= ele.max) {
                  colCharge = ele[state.across];
                }
              });
            } else colCharge = 0;
            if (
              (colCharge == null || colCharge == 0) &&
              (state.howcollect == "cod" || state.howcollect == "col")
            ) {
              colCharge = arr[arr.length - 1].charge;
            }
          }
          return {
            ...state,
            goods: action.payload,
            ins: action.payload,
            col: action.payload,
            valueCharge: insCharge,
            colValue: colCharge,
          };
        } catch (err : any) {
          console.log(err);
          return state;
        }
      }
      // case BOOK_INS_CHANGE: {
      //   try {
      //     if (!state.goods || state.goods.length == 0) throw "GOODS_NULL";
      //     const newIns = parseInt(action.payload, 10);
      //     const goods = parseInt(state.goods, 10);
      //     if (newIns > goods) throw "GREATER";
      //     let arr = null;
      //     if (state.risk == 0) throw "SELECT";
      //     if (state.risk == 1) {
      //       arr = state.company.insurance.owner;
      //     }
      //     if (state.risk == 2) {
      //       arr = state.company.insurance.carrier;
      //     }
      //     let insCharge = 0;
      //     arr.forEach((ele: any) => {
      //       if (newIns >= ele.min && newIns <= ele.max) {
      //         insCharge = ele["charge"];
      //       }
      //     });
      //     return {
      //       ...state,
      //       ins: newIns,
      //       valueCharge: insCharge,
      //     };
      //   } catch (err : any) {
      //     console.log(err);
      //     if (err == "GREATER")
      //       showMessage(
      //         "Insurance base value must be less than goods value",
      //         failed
      //       );
      //     else if (err == "GOODS_NULL")
      //       showMessage("Set goods value first", failed);
      //     return state;
      //   }
      // }
      case BOOK_VALUE_CHARGE_CHANGE: {
        return {
          ...state,
          valueCharge: action.payload,
        };
      }
      case BOOK_COL_CHANGE: {
        try {
          if (!state.goods || state.goods.length == 0) throw "GOODS_NULL";
          let newCol = parseInt(action.payload, 10);
          let goods = parseInt(state.goods, 10);
          if (newCol > goods) throw "GREATER";
          let arr = state.company[state.howcollect];
          if (!arr || arr.length === 0) throw "INVALID CHOICE";
          let colCharge = 0;
          arr.forEach((ele: any) => {
            if (newCol >= ele.min && newCol <= ele.max) {
              colCharge = ele["charge"];
            }
          });
          return {
            ...state,
            col: newCol,
            colValue: colCharge,
          };
        } catch (err : any) {
          console.log(err);
          if (err == "GREATER")
            showMessage(
              "Collection value must be less than goods value",
              failed
            );
          else if (err == "GOODS_NULL")
            showMessage("Set goods value first", failed);
          return state;
        }
      }
      case SET_RATE_ID : {
        return{
          ...state,
          rateId : action.payload
        }
      }
      case BOOKING_REFRESH: {
        try {
          let freight = state.fr;
          // const {Route}=state.bookService
          const hf = countNewHandlingCharges(
            state.packages,
            state.handlingChargeSlab,
            2// Route?.length *2
          );

          let bf = 0;
          freight = parseInt(String(freight), 10);
          bf = countBuiltyCharges(
            state.packages,
            state.builtyChargeSlab,
            state.across
          );

          return {
            ...state,
            fr: freight,
            hf: hf,
            bf: bf,
            gross: freight + hf + bf,
          };
        } catch (err : any) {
          console.log(err);
          switch (err) {
            case "AcrossError": {
              showMessage("Select Destination city first", failed);
              break;
            }
            default: {
              showMessage("Something went wrong", failed);
            }
          }
          return state;
        }
      }
      case BOOKING_EDIT_PACKAGE: {
        try {
          if (!state.across || !state.deliveryType) {
            throw new BookingError("Select Proper Destination first", "across");
          }
          if (
            state?.deliveryType?.value == "Branch To Branch" &&
            (!state.bookdestcity || !state.bookdestcity.value)
          ) {
            throw new BookingError(
              "Select Proper Destination first",
              "bookdestcity"
            );
          }
          if (
            state.deliveryType.value == "Home Delivery" &&
            (!state.receiver.pin || String(state.receiver.pin).length !== 6)
          ) {
            throw new BookingError(
              "Select Proper Destination first",
              "receiverPincode"
            );
          }
          const { bookingUpdate } = state;
          let grossFr=state.grossFr
          let packages = [];
          const { id, name } = action.payload;
          switch (name) {
            case "stack": {
              state.packages.forEach((p: any) => {
                console.log("for package : ", p);
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.stack = !p.stack;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "frag": {
              state.packages.forEach((p: any) => {
                console.log("for package : ", p);
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.frag = !p.frag;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "haz": {
              state.packages.forEach((p: any) => {
                console.log("for package : ", p);
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.haz = !p.haz;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "size": {
              const newSize = action.payload.val.value;
              state.packages.forEach((p: any) => {
                console.log("for package : ", p, action.payload);
                const units = [
                  { label: "Mt", value: "m" },
                  { label: "Cm", value: "cm" },
                  { label: "Inch", value: "in" },
                ];
                if (p.id == id) {
                  const temp: any = Object.assign({}, p);
                  temp.size = action.payload.val;
                  if (action.payload.val != "") {
                    const { sizes } = state;
                    sizes.forEach((size: any) => {
                      if (size.name == newSize) {
                        console.log("size matched");
                        const { l, b, h } = size;
                        temp.dimension = { l, b, h };
                        temp.weight = size.weight;
                        units.map((u) => {
                          if (u.value == size.unit) {
                            console.log("u: ", u);
                            temp.unit = u;
                          }
                        });
                      }
                    });
                  }
                  console.log("temp: ", temp);
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "mat": {
              state.packages.forEach((p: any) => {
                console.log("for package : ", p);
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.materialType = action.payload.val;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "pack": {
              state.packages.forEach((p: any) => {
                console.log("for package : ", p);
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.packingType = action.payload.val;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "qty": {
              state.packages.forEach((p: any) => {
                console.log("for package : ", p);
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.qty = action.payload.val;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "l": {
              state.packages.forEach((p: any) => {
                console.log("for package : ", p, action.payload);
                if (p.id == id) {
                  const temp = Object.assign({}, p),
                    dimension = Object.assign({}, temp.dimension);
                  dimension.l = action.payload.val;
                  temp.dimension = dimension;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "b": {
              state.packages.forEach((p: any) => {
                console.log("for package : ", p);
                if (p.id == id) {
                  const temp = Object.assign({}, p),
                    dimension = Object.assign({}, temp.dimension);
                  dimension.b = action.payload.val;
                  temp.dimension = dimension;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "h": {
              state.packages.forEach((p: any) => {
                console.log("for package : ", p);
                if (p.id == id) {
                  const temp = Object.assign({}, p),
                    dimension = Object.assign({}, temp.dimension);
                  dimension.h = action.payload.val;
                  temp.dimension = dimension;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "u": {
              console.log("\n\ngot to change unit : ", action.payload);
              const { val, id } = action.payload;
              packages = state.packages.map((item: any) =>
                item.id == id ? { ...item, unit: val } : item
              );
              break;
            }
            case "w": {
              state.packages.forEach((p: any) => {
                console.log("for package : ", p);
                if (p.id == id) {
                  const temp: any = Object.assign({}, p);
                  temp.weight = parseInt(action.payload.val, 10);
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "rate": {
              const resPackages: any[] = [];
              state.packages.forEach((p: any) => {
                console.log("for package : ", p, " condition : ", p.id == id);
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.rate = action.payload.val;
                  resPackages.push(temp);
                } else resPackages.push(p);
              });
              packages = resPackages;
              break;
            }
            case "amount": {
              const { val } = action.payload,
                amt = val;
              const resPackages: any[] = [];
              state.packages.forEach((p: any) => {
                console.log("for package : ", p, " condition : ", p.id == id);
                if (p.id == id) {
                  resPackages.push({ ...p, amount: isNaN(amt) ? "" : amt });
                } else resPackages.push(p);
              });
              packages = resPackages;
              break;
            }
            case "status": {
              state.packages.forEach((p: any) => {
                console.log("for package ans: ", p);
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.status = !p.status;
                  packages.push(temp);
                } else packages.push(p);
              });
              break;
            }
            case "fixRate": {
              const resPackages: any[] = [];
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.fixRate = action.payload.val;
                  resPackages.push(temp);
                } else resPackages.push(p);
              });
              packages = resPackages;
              break;
            }
            case "standardRateAmount": {
              const resPackages: any[] = [];
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.standardRateAmount = action.payload.val;
                  resPackages.push(temp);
                } else resPackages.push(p);
              });
              packages = resPackages;
              break;
            }
            case "standardRate": {
              const resPackages: any[] = [];
              state.packages.forEach((p: any) => {
                if (p.id == id) {
                  const temp = Object.assign({}, p);
                  temp.standardRate = action.payload.val;
                  resPackages.push(temp);
                } else resPackages.push(p);
              });
              packages = resPackages;
              break;
            }
          }
          // let transportCharges=new Map()
          let totalWeight=state.totalWeight
          if(name=="qty"||name=="w"||name=="amount"||name=="standardRate" ||name=="rate"){
            grossFr=0
            totalWeight=0
            let totalQty=0
            let minimumChargeAbleWeight=0
            packages.forEach((p: any) => {
              let qty = parseInt(p.qty, 10)
              let tWeight = parseInt(p.weight, 10)
              totalWeight+=qty*tWeight
              totalQty+=qty
            })
         packages=packages.map((p: any) => {      
              let qty = parseInt(p.qty, 10)
              let tRate = p.rate ? p.rate.value : "Direct"
              let tWeight = parseInt(p.weight, 10)
              let tAmount = p.amount
              let amount=0
            if (!isNaN(qty) && tRate == "Per package") {
                if( p?.standardRate?.length){
                  p?.standardRate?.map((standardRate:any)=>{
                    if(standardRate.serviceSubType.minimumChargeAbleWeight>totalWeight){
                      if(standardRate.serviceSubType.minimumChargeAbleWeight>minimumChargeAbleWeight){
                        minimumChargeAbleWeight=standardRate.serviceSubType.minimumChargeAbleWeight
                      }
                    }else{
                      let volumeDiscount=
                      standardRate?.volumeDiscount?.length>0?
                      standardRate?.volumeDiscount?.find((volumeDis:any)=>
                      volumeDis.minimumWeight<= totalWeight &&volumeDis.maximumWeight >= totalWeight  ):null
                      amount+=( standardRate.price * tWeight) *(volumeDiscount?((100-volumeDiscount.discount)/100):1);
                     let transportCharge= isNaN(qty * standardRate.price * tWeight) ? 0 :
                       (qty * standardRate.price * tWeight) *(volumeDiscount?((100-volumeDiscount.discount)/100):1);
                      //  let oldTransportCharges=transportCharges.get(standardRate.serviceSubType.company)
                      //  transportCharges.set(standardRate.serviceSubType.company,{
                      //   company:standardRate.serviceSubType.company,
                      //   amount:transportCharge+(oldTransportCharges?.amount||0) ,
                      //   price:standardRate.price,
                      //   originBranch:standardRate.originBranch,
                      //   destBranch:standardRate.destBranch,
                      //   qty:totalQty,
                      //   weight:totalWeight,
                      //   route:standardRate.route
                      // })               
                      grossFr += transportCharge
                    }
                  }) 
                }else{
                  grossFr += isNaN(qty * tAmount) ? 0 : qty * tAmount;
                }
              } else if (!isNaN(qty) && !isNaN(tWeight) && tRate == "Per Kg") {
              // let price=0 
                if( p?.standardRate?.length){
                  p?.standardRate?.map((standardRate:any)=>{
                    if(standardRate.serviceSubType.minimumChargeAbleWeight>totalWeight){
                      if(standardRate.serviceSubType.minimumChargeAbleWeight>minimumChargeAbleWeight){
                        minimumChargeAbleWeight=standardRate.serviceSubType.minimumChargeAbleWeight
                      }
                    }else{
                      let volumeDiscount= standardRate?.volumeDiscount?.length>0?
                      standardRate?.volumeDiscount?.find((volumeDis:any)=>
                      volumeDis.minimumWeight <= totalWeight &&volumeDis.maximumWeight >= totalWeight  ):null
                      amount+=( standardRate.price ) *(volumeDiscount?((100-volumeDiscount.discount)/100):1);
                     let transportCharge=   isNaN(qty * standardRate.price * tWeight) ? 0 : (qty * standardRate.price * tWeight)  *(volumeDiscount?((100-volumeDiscount.discount)/100):1);
                    //  let oldTransportCharges=transportCharges.get(standardRate.serviceSubType.company)
                    //  transportCharges.set(standardRate.serviceSubType.company,
                    //   {amount:transportCharge+(oldTransportCharges?.amount||0),
                    //     company:standardRate.serviceSubType.company,
                    //     price:standardRate.price,
                    //     originBranch:standardRate.originBranch,
                    //     destBranch:standardRate.destBranch,
                    //     qty:totalQty,
                    //     weight:totalWeight,
                    //     rateType:tRate,
                    //     route:standardRate.route
                    //   })
                      
                      grossFr += transportCharge
                    }
                  }) 
                }else{
                  grossFr += isNaN(qty * tAmount * tWeight) ? 0 : qty * tAmount * tWeight;
                }
              }
              // if(typeof p?.fixRate?.deliveryCharges=="number"){
              //   fixDeliveryCharge=p.fixRate.deliveryCharges
              //   fixDeliveryChargeExists=true
              // }
              // if(fixDeliveryCharge>p?.fixRate?.deliveryCharges &&typeof p?.fixRate?.deliveryCharges=="number"){
              //   fixDeliveryCharge=p?.fixRate?.deliveryCharges
              // }
              // if (toBranchData) {
              //   console.log("we are in calculateIntermediateCharge");
              //   intermediateCharges += calculateIntermediateCharge(
              //     p.qty,
              //     p.amount,
              //     p.rate && p.rate.value,
              //     fromBranchdata,
              //     toBranchData
              //   );
              // }
              return {...p, amount:p.standardRate?.length>0?amount:p.amount}
            });
            // console.log("companyWiseAmount : ",transportCharges)
            console.log("totalWeight : ",totalWeight)
            if(minimumChargeAbleWeight>totalWeight){
              // transportCharges=new Map()
              let newGrossFr=0
              let standardRate:any=[]
              packages.map((pkg:any)=>{
                if(pkg.standardRate?.length){
                    standardRate=pkg.standardRate
                }
              })
              // packages.forEach((pkg:any)=>{
              standardRate?.map((standardRate:any)=>{
                let weight= minimumChargeAbleWeight
                let transportCharge=isNaN( standardRate.price * weight) ? 0 :  standardRate.price * weight;
                // let oldTransportCharges=transportCharges.get(standardRate.serviceSubType.company)
                // transportCharges.set(standardRate.serviceSubType.company,
                //   {amount:transportCharge+(oldTransportCharges?.amount||0) ,
                //   company:standardRate.serviceSubType.company,
                //   price:standardRate.price,
                //   originBranch:standardRate.originBranch,
                //   destBranch:standardRate.destBranch,
                //    qty:totalQty,
                //   weight:totalWeight,
                //   // rateType:tRate,
                //   route:standardRate.route
                // })
                newGrossFr += transportCharge
              }) 
              // })
              if(standardRate.length){
              packages= packages.map((pkg:any)=>{
                let newAmount=newGrossFr/(totalQty * ( pkg.rate.value=="Per Kg"?pkg.weight:1 ))
              return { ...pkg, amount:newAmount.toFixed(1) }     
              })

              if(newGrossFr>0){
                grossFr=newGrossFr
              }
              }
            }
            // console.log("companyWiseAmount : ",transportCharges)


          }

          if (!state.packages || state.packages.length == 0) return state;
          let freight = state.fr;
          // const {Route}=state.bookService
          const hf = countNewHandlingCharges(
            packages,
            state.handlingChargeSlab,
            2// Route?.length *2
          );

          const bf = countBuiltyCharges(
            packages,
            state.builtyChargeSlab,
            state.across
          );
          freight = parseInt(String(freight), 10);

          const insObj: any = {};
          if (name === "qty") {
            if (
              state.valueChargeEnabled &&
              state.insuredCompanies &&
              state.insuredCompanies.includes(state.company.id) &&
              state.bookdestbranch &&
              state.insuredCompanies.includes(state.bookdestbranch.company._id)
            ) {
              insObj.insMinValue = Math.max(
                state.minValueCharge,
                state.perPackageValueCharge *
                  packages.reduce((pv: any, cv: any) => pv + Number(cv.qty), 0)
              );
              if (Number(state.risk) === 0) {
                insObj.risk = "1";
              }
            }
          }

          return {
            ...state,
            ...insObj,
            fr: freight,
            totalWeight,
            grossFr,
            hf: hf,
            bf: bf,
            gross: freight + hf + bf,
            packages,
            bookingUpdate: {
              ...bookingUpdate,
              packages: true,
              payment: true,
            },
            // transportCharges:Array.from(transportCharges.values())
          };
        } catch (err : any) {
          if (err instanceof BookingError) {
            console.log("got the correct one");
            showMessage(err.message, failed);
          }
          console.log(err, " is ", err instanceof BookingError);
          return state;
        }
      }
      case SET_UPDATE_PKG_FETCHDOCKET: {
        const val = action.payload;
        console.log("editdkt", val);

        let prevArr = [...state.fetchDocketUpdate];
        let arr = prevArr.includes(val) ? prevArr : [val, ...prevArr];

        console.log("arr", arr);
        // let temp:any={}
        // if (action.payload.val && action.payload.val.size) {
        //   const newSize = action.payload.val.size
        //   temp.size = newSize
        //   const units = [
        //     { label: "Mt", value: "m" },
        //     { label: "Cm", value: "cm" },
        //     { label: "Inch", value: "in" },
        //   ];
        //   const { sizes } = state;
        //   sizes.forEach((size: any) => {
        //     if (size.name == newSize) {
        //       console.log("size matched");
        //       const { l, b, h } = size;
        //       temp.dimension = { l, b, h };
        //       temp.weight = size.weight;
        //       units.map((u:any) => {
        //         if (u.value == size.unit) {
        //           console.log("u: ", u);
        //           temp.unit = u.value;
        //         }
        //       });
        //     }
        //   });
        // }else {
        //   temp = val
        // }
        return {
          ...state,
          fetchDocketUpdate: [...arr],
        };
      }
      case BOOK_ADD_PACKAGE: {
        const { bookingUpdate } = state;
        const packages = state.packages.slice();
        packages.push(action.payload);
        return {
          ...state,
          packages,
          bookingUpdate: {
            ...bookingUpdate,
            packages: true,
          },
        };
      }
      case ADD_PACKAGE_SIZE:{
        // const {
        //   sizes,
        // } = action.payload;
        const { bookingUpdate } = state;
        const sizes = state.sizes.slice();
        sizes.push(action.payload);
        return {
          ...state,
          sizes,
          bookingUpdate: {
            ...bookingUpdate,
            sizes: true,
          },
        };
      }
      // case DEL_PACKAGE_SIZE: {
      //   try{
      //     const {_id } =action.payload,
      //     sizes = state.sizes
      //     .filter((p: any)=> p.id != _id)
      //     .map((p: any, idx: number) => ({ ...p, _id: idx }));
      //     const { bookingUpdate } = state;
      //     return {
      //       ...state,
      //       sizes: sizes,
      //       bookingUpdate: {
      //         ...bookingUpdate,
      //         sizes: true,
      //       },
      //     };
      //   }
      //   catch(err){
      //     console.log(err);
      //     return state;
      //   }
      // }

      case BOOK_DEL_PACKAGE: {
        try {
          const { id } = action.payload,
          packages = state.packages
          .filter((p: any) => p.id != id)
          .map((p: any, idx: number) => ({ ...p, id: idx }));
          // const {Route}=state.bookService
          const hf = countNewHandlingCharges(
            packages,
            state.handlingChargeSlab,
            2// Route?.length *2
          );
          const bf = countBuiltyCharges(
            packages,
            state.builtyChargeSlab,
            state.across
          );

          const { bookingUpdate } = state;
          return {
            ...state,
            packages: packages,
            hf,
            bf,
            bookingUpdate: {
              ...bookingUpdate,
              packages: true,
              payment: true,
            },
          };
        } catch (err : any) {
          console.log(err);
          return state;
        }
      }
      case SET_ACROSS: {
        if (typeof action.payload !== "string") return state;
        const { bookingUpdate } = state;
        let msg: any = acrossFun(action.payload);
        console.log("msg got : ", msg);
        msg += " charges will be calculated";
        showMessage(msg, success,1500);
        return {
          ...state,
          across: action.payload,
          bookingUpdate: { ...bookingUpdate, packages: true },
        };
      }
      case SET_LR: {
        return {
          ...state,
          LR: action.payload,
        };
      }
      case SET_ORANGE_LR: {
        return {
          ...state,
          orangeLR: action.payload,
        };
      }
      case BOOK_BRANCHES: {
        const { payload } = action;
        try {
          const { bookingUpdate } = state;
          const cityMap = new Map(
            payload
              .filter((p: any) => p.address && p.address.city)
              .map((p: any) => [p.address.city._id, p.address.city.name])
          );

          const cities = [];
          for (let entry of cityMap.entries()) {
            console.log("entry is : ", entry);
            cities.push({ name: entry[1], _id: entry[0] });
          }

          cities.sort((a: any, b: any) =>
            a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
          );

          return {
            ...state,
            filbranches: action.payload.filter(
              (br: any) => br && br.operation && br.operation.delivery
            ),
            bookCities: cities,
            bookingUpdate: { ...bookingUpdate, destCity: true },
          };
        } catch (err : any) {
          console.log(err);
          return state;
        }
      }
      case BOOK_CHANGE_DELIVERY_TYPE: {
        return {
          ...state,
          deliveryType: action.payload,
        };
      }
      case BOOK_CHANGE_FEDEX_SERVICE_TYPE: {
        return {
          ...state,
          fedexServiceType: action.payload,
        };
      }
      case SET_FEDEX_SERVICE_OPTIONS: {
        return {
          ...state,
          fedexServiceTypeOptions: action.payload,
        };
      }
      case BOOK_DEST_BRANCH: {
        const { value } = action.payload;
        const {
          filbranches,    
        } = state;
        console.log("filbranches", filbranches);
        const branch = filbranches.find((b: any) => b._id === value);
        const receiverAddress = branch.address;
        if (!branch || !branch.address || !branch.address.city) return state;
        const additionalChange: any = {};



        console.log(
          "reciever-----",
          receiverAddress,
          "\nbranch found : ",
          branch
        );
        const receiver = {
          ...state.receiver,
        };
        return {
          ...state,
          receiver,
          receiverBranchAddress: receiverAddress,
          bookdestbranch: action.payload,
          bookdestcity: {
            label: branch.address.city.name,
            value: branch.address.city._id,
          },
          bookTouches: state.bookTouches.setIn(["bookdestbranch"], true),
          receiverAddressLock: true,
          bookingUpdate: {
            ...state.bookingUpdate,
            destCity: true,
          },
          ...additionalChange,
        };
      }
      case BOOK_DEST_SERVICE: {
        const packages = state.packages
        // const {Route}=action.payload
            let hf =0
            if(packages.length){
              hf= countNewHandlingCharges(
                packages,
                state.handlingChargeSlab,
                2// (Route?.length) *2
              );
            }

           return {
            ...state,
            hf: hf,
            bookService: action.payload,
            bookTouches: state.bookTouches.setIn(["bookService"], true),
        } 
      }
      case RESET_EDIT: {
        const exclude = action.payload ? action.payload.exclude : {};

        exclude.filbranches = 1;
        const retain: any = {};
        if (exclude) {
          Object.keys(exclude).forEach((key) => (retain[key] = state[key]));
        }
        console.log("retain : ", retain);
        const bookingUpdate = {
          packages: true,
          destCity: true,
          payment: true,
        };
        const fixRateCustomer={
          name:"",
          contact:"",
          customerType:""
        }
        const sender = {
            name: "",
            contact: "",
            gst: "",
            l1: "",
            l2: "",
            pin: "",
            city: "",
            senderCode: "",
            isSenderDisable: false,
            isContactValidated:false
          },
          receiver = {
            name: "",
            contact: "",
            gst: "",
            l1: "",
            l2: "",
            pin: "",
            city: "",
            receiverCode: "",
            isReceiverDisable: false,
            isContactValidated:false
          };
        const otherValues: any = {
          LR: "",
          orangeLR: "",
          hf: "",
          dc: "",
          docketNumber: "",
          bf: "",
          fr: "",
          grossFr:0,
          totalWeight:0,
          risk: "0",
          ins: 0,
          valueCharge: 0,
          insMinValue: 0,
          isCol: false,
          howcollect: "",
          pickupCharge: 0,
          deliveryCharge: 0,
          col: 0,
          colValue: 0,
          packValue: 0,
          colMonth: 0,
          customPack: "null",
          bookingRate: 0,
          bookingMode: null,
          nonCashPaymentType: "",
          nonCashTxnId: "",
          nonCashTxnImage: "",
          nonCashTxnsNumber:"",
          onlinePaymentStatus:"",
          goods: "",
          docketStatus: false,
          bill: "",
          purpose: { label: "Commercial", value: "C" },
          bookingStatus: true,
          franchiseNumber: null,
          remark: "",
          pickupContact: "",
          creditNumber: "",
          bookingCredit: initCredit,
          focOTP: "",
          focContact: "",
          isFocOTPSent: false,
          senderOTP: "",
          isSenderNew: false,
          isSenderOTPSent: false,
          isSenderVerificationDialog: false,
          isResendDisabled: false,
          isEmployeeVerificationRequired: true,
          employeeOTP: "",
          isEmployeeOTPSent: "",
          isEmployeeVerificationDialog: false,
          employeeContact: "",
          employeeName: "",
          isEmployeeNew: false,
          isEmployeeFetched: false,
          //isEmployeeContactValidate:false,
          senderType: "",
          employeeImage: null,
          senderPhoto: null,
          individualImage: null,
          individualFleet:initState.individualFleet,
          gross:0,
          intermediateCharge:0,
          ewayGoods : [],
          ewayBillNo : [],
          eWayBills : [],
          imageGalleryArr : [],
          doorDelivery: {
            deliveryCharge:0,
            origin: {},
            l1: "",
            l2: "",
            officeName: "",
            floor: "",
            tower: "",
            nearby_landmark: "",
            doorDeliveryPaymentMode: "",
            pincode: "",
            city: {},
            area: "",
            state: "",
            shouldDoorDeliveryBook: false,
            doorDelDocketNumber:"",
            serviceType: "",
            delCity:{}
          },
        };
        if (
          state.loginType == "B" &&
          state.opBranch &&
          state.opBranch.address
        ) {
          const { address } = state.opBranch;
          console.log("state.allcities: ", state.allcities);
          let city: any = "";
          if (state.allcities) {
            city = state.allcities.find((c: any) => c._id == address.city);
            if (city) {
              city = city.name;
            }
          }
          const sender = {
            name: null,
            gst: null,
            contact: "",
          };
          otherValues.sender = sender;
          otherValues.senderAddressLock = true;
        }
        return {
          ...state,
          orangeLR: null,
          resetPackageErrors: true,
          receiverGstInvalid: false,
          senderGstInvalid: false,
          senderForceNameRemoveDueToGst: false,
          receiverForceNameRemoveDueToGst: false,
          senderGstRelativeFieldDisable: false,
          receiverGstRelativeFieldDisable: false,        
          ewb: "",
          sender,
          receiver,
          fixRateCustomer,
          bookingUpdate,
          editDocket: false,
          isEwayBill: false,
          deliveryType: {
            value: "Branch To Branch",
            label: "Branch To Branch",
          },
          bookdestcity: "",
          bookdestbranch: "",
          actDestBranch: "",
          receiverBranchAddress:"",
          receiverGst: false,
          senderGst: false,
          bookService:{label:"",value:"", Route: []},
          bookErrors: IMap(),
          bookTouches: IMap(),
          specificDelivery: false,
          delDocket: {
            docket: {
              status: true,
            },
          },
          packages: [
            {
              id: 0,
              qty: "",
              materialType: "",
              packingType: "",
              size: "custom",
              dimension: { l: "", b: "", h: "" },
              weight: "",
              rate: "Direct",
              unit: "",
              amount: "",
              stack: false,
              haz: false,
              frag: false,
              status : ""
            },
          ],
          fetchDocketUpdate: [],
          ...otherValues,
          ...retain,
          resetBooking: true,
          bookingTime: null,
          remarks: "",
          contactData : null,
          senderGstRemoveReqData: {
            dialog: false,
            step: 1,
            gstData: [],
            removedGst: "",
            newGst: "",
            primaryContact: ""
          },
          receiverGstRemoveReqData: {
            dialog: false,
            step: 1,
            gstData: [],
            removedGst: "",
            newGst: "",
            primaryContact: ""
          },
          senderSecondaryContactReqData: {
            dialog: false,
            step: 1,
            secondaryContactData: [],
            newContact: "",
            removedContact: "",
            primaryContact: ""
          },
          receiverSecondaryContactReqData: {
            dialog: false,
            step: 1,
            secondaryContactData: [],
            newContact: "",
            removedContact: "",
            primaryContact: ""
          },
          senderGstContacts: [],
          receiverGstContacts: []
        };
      }
      case SET_PACKAGE_ERROR_FLAG_FALSE: {
        if (state.resetPackageErrors === true)
          return {
            ...state,
            resetPackageErrors: false,
          };
        else {
          return state;
        }
      }
      case BOOK_PAY_MODE: {
        const { bookingUpdate } = state;
        return {
          ...state,
          bookingMode: action.payload,
          creditNumber: "",
          bookingCredit: initCredit,
          bookingUpdate: { ...bookingUpdate, payment: true },
        };
      }
      case NONCASH_PAYMENT_TYPE: {
        return {
          ...state,
          nonCashPaymentType: action.payload,
        };
      }

      case NONCASH_TXN_ID: {
        return {
          ...state,
          nonCashTxnId: action.payload,
        };
      }

      case NONCASH_TXN_IMAGE: {
        return {
          ...state,
          nonCashTxnImage: action.payload,
        };
      }
      case NONCASH_TXNS_NUMBER: {
        return {
         ...state,
        nonCashTxnsNumber: action.payload,
        };
      }
      case SET_ONLINE_PAYMENT_STATUS: {
        return {
         ...state,
         onlinePaymentStatus:action.payload,
        };
      }
      
      case CREDIT_NUM_CHANGE: {
        const { bookingUpdate } = state;
        return {
          ...state,
          creditNumber: action.payload,
          bookingUpdate: {
            ...bookingUpdate,
            payment: true,
          },
        };
      }
      case BOOK_CREDIT_SET: {
        const { fromBranch_permissions, toBranch_permissions, fleet_permissions } = action.payload.data;
        const { bookingUpdate , bookdestbranch} = state;
        const { loginType, opBranch, opFleet } = action.payload.user;
        let creditPermission = false;
        if (loginType == "B") {
          if(fromBranch_permissions.length === 0){
            creditPermission = toBranch_permissions.find(
              (b: any) => b.branch._id === bookdestbranch.value
            )
              ? true
              : false;
          }

          else if(toBranch_permissions.length === 0){
            creditPermission = fromBranch_permissions.find(
              (b: any) => b.branch._id === opBranch._id
            )
              ? true
              : false;
          }
          else{
            creditPermission = fromBranch_permissions.find(
              (b: any) => b.branch._id === opBranch._id
            ) && toBranch_permissions.find(
              (b: any) => b.branch._id === bookdestbranch.value
            )
              ? true
              : false;
          }
        } else {
          creditPermission = fleet_permissions.find(
            (b: any) => b.fleet == opFleet._id
          )
            ? true
            : false;
        }
        return {
          ...state,
          bookingCredit: {
            ...action.payload.data,
            doesEntityHasCreditPermission: creditPermission,
          },
          bookingUpdate: {
            ...bookingUpdate,
            payment: true,
          },
        };
      }
      case BOOK_CREDIT_RESET: {
        return { ...state, bookingCredit: initCredit };
      }
      case SET_SENDER_ADDRESS_FROM_OPBRANCH: {
        if (!state.opBranch || !state.opBranch.address) return state;
        const { address } = state.opBranch;
        console.log("state.allcities: ", state.allcities);
        let city: any = "";
        if (state.allcities) {
          city = state.allcities.find((c: any) => c._id == address.city);
          if (city) {
            city = city.name;
          }
        }
        const sender = {
          ...state.sender,
        };
        return {
          ...state,
          sender,
          senderAddressLock: true,
        };
      }
      case SET_RECEIVER_ADDRESS_FROM_OPBRANCH: {
        const receiver = {
          ...state.receiver,
        };
        return {
          ...state,
          receiver,
          receiverAddressLock: true,
        };
      }
      case SET_SENDER_ADDRESS_FROM_OTHER: {
        const sender = state.sender;
        let newSender = {
          ...sender,
        };
        return {
          ...state,
          sender: newSender,
        };
      }
      case SET_RECEIVER_ADDRESS_FROM_OTHER: {
        const address = action.payload;
        console.log("address: ", address, action.payload);
        const receiver = state.receiver;
        let newReceiver = {
          ...receiver,
        };
        return {
          ...state,
          receiver: newReceiver,
        };
      }
      case CHANGE_TO_CREDIT_SUCCESS: {
        showMessage("Payment mode changed to credit!", success);
        const { credit, docket } = action.payload;
        const delDocket = Object.assign({}, state.delDocket);
        delDocket.docket = docket;
        return {
          ...state,
          credit: credit.response.credit,
          delDocket,
        };
      }
      case VALIDATION_ERROR: {
        const { path, message } = action.payload;
        console.log(path, message);
        console.log("bookErrors", state.bookErrors);

        let oldMessage = state.bookErrors.getIn(path);
        if (oldMessage != message || oldMessage.length != message.length) {
          return {
            ...state,
            bookErrors: state.bookErrors.setIn(path, message),
          };
        }
        return state;
      }
      case SPECIFIC_DELIVERY: {
        if (
          state.editDocket &&
          state.delDocket &&
          state.delDocket.docket &&
          state.delDocket.docket.delSpecific === true
        ) {
          return state;
        }

        return {
          ...state,
          specificDelivery: !state.specificDelivery,
        };
      }
      case SET_SENDER_BY_GST: {
        const {
          trade_name,
          // address1: l1,
          // address2: l2,
          // pincode,
          senderForceNameRemoveDueToGst,
          senderGstRelativeFieldDisable
        } = action.payload;
        return {
          ...state,
          sender: {
            ...state.sender,
            name: toRSOption(trade_name),
          },
          bookTouches: state.bookTouches.setIn(["sender", "name"], true),
          senderGstInvalid: false,
          senderForceNameRemoveDueToGst,
          senderGst: true,
          senderGstRelativeFieldDisable
        };
      }
      case SET_RECEIVER_BY_GST: {
        const {
          trade_name,
          // address1: l1,
          // address2: l2,
          // pincode,
          receiverForceNameRemoveDueToGst,
          receiverGstRelativeFieldDisable
        } = action.payload;
        console.log("action.payload: ", action.payload);

        const { otherAddr, name } = state.receiver;
        console.log("otherAddr: ", otherAddr, name);
        return {
          ...state,
          receiver: {
            ...state.receiver,
            name: toRSOption(trade_name),
          },
          bookTouches: state.bookTouches.setIn(["receiver", "name"], true),
          receiverGstInvalid: false,
          receiverGst: true,
          receiverForceNameRemoveDueToGst,
          receiverGstRelativeFieldDisable
        };
      }
      case BUILTY_UPDATE_ACK: {
        return { ...state, updateBuilty: false };
      }
      case BUILTY_UPDATE_REQ: {
        return { ...state, updateBuilty: true };
      }
      case PACKAGE_UPDATE_ACK: {
        const { bookingUpdate } = state;
        return {
          ...state,
          bookingUpdate: { ...bookingUpdate, packages: false },
        };
      }
      case PAYMENT_UPDATE_ACK: {
        const { bookingUpdate } = state;
        return {
          ...state,
          bookingUpdate: { ...bookingUpdate, payment: false },
          resetBooking: false,
        };
      }
      case ID_FETCH_ACK: {
        return { ...state, idFetch: false };
      }
      case BILL_FETCH_ACK: {
        return { ...state, billFetch: false };
      }
      case DOCKETS_FETCH_ACK: {
        return {
          ...state,
          docketsFetch: false,
        };
      }
      case SENDER_GST_INVALID: {
        return {
          ...state,
          senderGstInvalid: true,
          sender: {
            ...state.sender,
            name: "",
          },
        };
      }
      case RECEIVER_GST_INVALID: {
        return {
          ...state,
          receiverGstInvalid: true,
          receiver: {
            ...state.receiver,
            name: "",
          },
        };
      }
      case RESET_RECEIVER_ADDRESS: {
        return {
          ...state,
          receiver: {
            ...state.receiver,
            l1: "",
            l2: "",
            pin: "",
            city: "",
          },
          receiverAddressLock: false,
        };
      }

      case SHOW_EWAYBILL_LOADER: {
        return {
          ...state,
          ewayBillLoading: true,
        };
      }
      case HIDE_EWAYBILL_LOADER: {
        return {
          ...state,
          ewayBillLoading: false,
        };
      }
      case SHOW_GST_LOADER: {
        return {
          ...state,
          gstLoading: true,
        };
      }
      case HIDE_GST_LOADER: {
        return {
          ...state,
          gstLoading: false,
        };
      }
      case CREDIT_NUMBER_HIDE_LOADER: {
        return {
          ...state,
          creditNumberLoading: false,
        };
      }
      case CREDIT_NUMBER_SHOW_LOADER: {
        return {
          ...state,
          creditNumberLoading: true,
        };
      }
      case SHOW_GST_LOADING_FOR_RECEIVER: {
        return {
          ...state,
          gstLoadingReceiver: true,
        };
      }
      case HIDE_GST_LOADING_FOR_RECEIVER: {
        let { gstLoadingReceiver } = state;
        if (gstLoadingReceiver === true)
          return {
            ...state,
            gstLoadingReceiver: false,
          };
        else {
          return state;
        }
      }
      case RESET_SENDER_ADDRESS: {
        return {
          ...state,
          sender: {
            ...state.sender,
            l1: "",
            l2: "",
            pin: "",
            city: "",
          },
          senderAddressLock: false,
        };
      }
      case SET_ROUTES: {
        try {
          const { franchise, own } = action.payload;
          const routes = franchise
            .map((r: any) => ({ ...r, isFranchise: true }))
            .concat(own);
          return {
            ...state,
            bookRoutes: routes,
          };
        } catch (err : any) {
          console.log(err);
          return state;
        }
      }
      case SET_SETTINGS: {
        const {
          valueChargeEnabled,
          minValueCharge,
          perPackageValueCharge,
          insuredCompanies,
          // handlingChargeSlab,
          deliveryChargesSlab,
          builtyChargeSlab,
          sizes,
        } = action.payload;
        return {
          ...state,
          valueChargeEnabled,
          minValueCharge,
          perPackageValueCharge,
          insuredCompanies,
          // handlingChargeSlab,
          deliveryChargesSlab,
          builtyChargeSlab,
          sizes,
        };
      }
      case SET_HANDLING_CHARGE_SLAB:{
        return {
          ...state,
          handlingChargeSlab:action.payload
        };
      }
      case "CHANGE_BOOKING_COUNTERS": {
        return {
          ...state,
          bookingCounters: action.payload,
        };
      }
      case SET_LAST_BOOKING:{
      //  console.log("lastBookingDetail :",action.payload)
        return {
          ...state,
          lastBookingDetail:action.payload
        } 
      }  
      case SET_SENDER_FORCE_NAME_REMOVE_DUE_TO_GST : {
        return {
          ...state,
          senderForceNameRemoveDueToGst: action.payload
        } 
      }
      case SET_RECEIVER_FORCE_NAME_REMOVE_DUE_TO_GST : {
        return {
          ...state,
          receiverForceNameRemoveDueToGst: action.payload
        } 
      }
      case SET_SENDER_GST_REMOVE_REQ_DATA: {
        return {
          ...state,
          senderGstRemoveReqData: {
            ...state.senderGstRemoveReqData,
            ...action.payload
          },
        };
      }
      case SET_RECEIVER_GST_REMOVE_REQ_DATA: {
        return {
          ...state,
          receiverGstRemoveReqData: {
            ...state.receiverGstRemoveReqData,
            ...action.payload
          },
        };
      } 
      case SET_SENDER_SECONDARY_CONTACT_REQ_DATA: {
        return {
          ...state,
          senderSecondaryContactReqData: {
            ...state.senderSecondaryContactReqData,
            ...action.payload
          },
        };
      }
      case SET_RECEIVER_SECONDARY_CONTACT_REQ_DATA: {
        return {
          ...state,
          receiverSecondaryContactReqData: {
            ...state.receiverSecondaryContactReqData,
            ...action.payload
          },
        };
      }
      case SET_SENDER_GST_CONTACTS : {
        return {
          ...state,
          senderGstContacts: action.payload
        }
      }
      case SET_RECEIVER_GST_CONTACTS : {
        return {
          ...state,
          receiverGstContacts: action.payload
        }
      }
      case SET_GENERAL_WARNING: {
        return {
          ...state,
          generalWarning: action.payload
        }
      }
      default: {
        return state;
      }
    }
  } catch (err : any) {
    console.log("error for : ", action.type);
    console.log(err);
  }
};

export default reducer;
