import { fromJS } from "immutable";
import * as Yup from "yup";

import {
  GET_BRANCHES_BY_COMPANY,
  GET_CITY,
  GETDETAILS,
  CHANGE_INPUT,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAILED,
  USER_EDIT_SUCCESS,
  USER_EDIT_FAILED,
  RESET_USER_FORM,
  RESET_USER_FORM_NEW_USER,
  ASSIGN_BRANCH_FAILED,
  ASSIGN_FLEET_FAILED,
  ASSIGN_FLEET,
  ASSIGN_BRANCH,
  FETCH_DEPARTMENT_LIST,
} from "../constants/user";
import { failed, showMessage, success } from "../utils/message";
import {
  isArrayCheck,
  sortBranchArray,
} from "../containers/generics/CheckArray";

const booleanSchema = (defaultValue: boolean) => ({
  name: {
    fName: defaultValue,
    mName: defaultValue,
    lName: defaultValue,
  },
  dob: defaultValue,
  doj: defaultValue,
  address: {
    l1: defaultValue,
    l2: defaultValue,
    city: defaultValue,
    pincode: defaultValue,
  },
  role: defaultValue,
  contact: defaultValue,
  maxBalance: defaultValue,
  password: defaultValue,
  email: defaultValue,
  pendingPaidLeaveCount: defaultValue,
  expenseDepartment: defaultValue,
  businessType: defaultValue,
  overhead: defaultValue,
  bankDetails: {
    bankName: defaultValue,
    beneficiary: defaultValue,
    accountNumber: defaultValue,
    ifscCode: defaultValue
  },
  designation: defaultValue,
  docVerified:defaultValue,
});

const validationSchema = fromJS({
  contact: Yup.string()
    .required("Contact number is required")
    .min(10, "Contact must be 10 digits")
    .max(10, "Contact must be 10 digits"),
  maxBalance: Yup.string()
    .required("Maximum balance is required")
    .min(0, "maximum amount can not be negative"),
  name: {
    fName: Yup.string()
      .required("First name is required")
      .min(3, "too short")
      .max(20, "Too long"),
    mName: Yup.string().max(10),
    lName: Yup.string()
      .required("Last name is required")
      .min(3, "too short")
      .max(20, "Too long"),
  },
  address: {
    l1: Yup.string()
      .required("Line 1 address is required")
      .min(3, "too short")
      .max(65, "Too long"),
    l2: Yup.string()
      .required("Line 2 address is required")
      .min(3, "too short")
      .max(65, "Too long"),
    city: Yup.string().required("City is required"),
    pincode: Yup.string()
      .required("Pincode is required")
      .max(10),
  },
  password: Yup.string()
    .required()
    .min(3, "Password should be minimum 6 characters"),
  email: Yup.string()
    .email()
    .required(),
  expenseDepartment: Yup.string(),
  businessType: Yup.string().required("Business type is required"),
  overhead: Yup.string().required("Overhead is required"),
});

const validate = (schema: any, obj: any) => {
  try {
    if (!schema) return false;
    schema.validateSync(obj);
    return false;
  } catch (err:any) {
    console.log(err);
    return err.message;
  }
};

const initialState = fromJS<any>({
  values: {
    _id: "",
    type: "add",
    contact: "",
    maxBalance: "",
    name: {
      fName: "",
      // mName: '',
      lName: "",
    },
    role: "",
    dob: new Date(),
    doj: "",
    address: {
      l1: "",
      l2: "",
      city: "",
      pincode: "",
    },
    active: false,
    photoReq: false,
    pendingPaidLeaveCount: 0,
    docVerified:false,
    password: "",
    email: "",
    company: "",
    assignedBranch: [],
    assignedFleet: [],
    assignedDepartment: [],
    allbranches: [],
    userShiftDuty: {
      startTime: "",
      endTime: "",
    },
    businessType: "",
    overhead: "",
    userTypeId: "",
    expenseDepartment: "",
    reportingManager: "",
    referenceUsers:[],
    userRef1Doc1:[],
      userRef1Doc2:[],
      userRef2Doc1:[],
      userRef2Doc2:[],
    bankDetails: {
      bankName: "",
      beneficiary: "",
      accountNumber: "",
      ifscCode: ""
    },
    designation: ""
  },
  assignedBranches: [],
  departMentList: [],
  errors: { ...booleanSchema(true) },
  touched: { ...booleanSchema(false) },
});

export default (state = initialState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case GET_BRANCHES_BY_COMPANY:
      let allBranches = action.payload;
      let sortedBranches = allBranches.sort(sortBranchArray);
      return state.setIn(
        ["values", "allbranches"],
        isArrayCheck(action.payload)
          ? sortedBranches.map((b: { branchName: string; _id: string }) => ({
              label: b.branchName,
              value: b._id,
            }))
          : []
      );
    case GET_CITY:
      return state.setIn(["values", "address", "city"], action.payload);
    case GETDETAILS:
      // const { dob } = action.payload, { d, m, y } = dob
      const { branches, fleets, departments } = action.payload;
      console.log(action.payload, "data contact");
      const values = {
        type: "edit",
        ...action.payload,
        userRef1Doc1:[],
        userRef1Doc2:[],
        userRef2Doc1:[],
        userRef2Doc2:[],
        companyId: action.payload.company,
        pendingPaidLeaveCount:action.payload.pendingPaidLeaveCount,
        designation:action.payload.designation,
        userShiftDuty: action.payload.userShiftDuty
          ? {
              startTime: action.payload.userShiftDuty.startTime,
              endTime: action.payload.userShiftDuty.endTime,
            }
          : { startTime: "", endTime: "" },
        bankDetails: action.payload.bankDetails
          ? {
              bankName: action.payload.bankDetails.bankName,
              beneficiary: action.payload.bankDetails.beneficiary,
              accountNumber: action.payload.bankDetails.accountNumber,
              ifscCode: action.payload.bankDetails.ifscCode,
            }
          : { bankName: "", beneficiary: "", accountNumber: "", ifscCode: "" },
        assignedBranch: isArrayCheck(branches)
          ? branches.map((b: { branchName: string; _id: string }) => ({
              label: b.branchName,
              value: b._id,
            }))
          : [],
        assignedFleet: isArrayCheck(fleets)
          ? fleets.map((f: { regNumber: string; _id: string }) => ({
              label: f.regNumber,
              value: f._id,
            }))
          : [],
        assignedDepartment: isArrayCheck(departments)
          ? departments.map((f: { departmentName: string; _id: string }) => ({
              label: f.departmentName,
              value: f._id,
            }))
          : [],
      };
      return state
        .setIn(["values"], { ...values })
        .setIn(["errors"], { ...booleanSchema(true) });

    case CHANGE_INPUT: {
      const { key, val } = action.payload;
      return state
        .setIn(["values", ...key], val)
        .setIn(["touched", ...key], true)
        .setIn(["errors", ...key], validate(validationSchema.getIn(key), val));
    }
    case "SET_USER_ERROR": {
      const { key } = action.payload;
      return state.setIn(
        ["errors", ...key],
        validate(validationSchema.getIn(key), "")
      );
    }
    case FETCH_DEPARTMENT_LIST: {
      const temp = isArrayCheck(action.payload)
        ? action.payload.map((a: { departmentName: string; _id: string }) => ({
            label: a.departmentName,
            value: a._id,
          }))
        : [];
      return state.set("departMentList", temp);
    }
    case USER_CREATE_SUCCESS:
      showMessage("User Created Successfully !", success);
      return state;
    case USER_CREATE_FAILED:
      showMessage("Could not create user !", success);
      return state;
    case USER_EDIT_SUCCESS:
      showMessage("User Edited Successfully !", success);
      return state;
    case USER_EDIT_FAILED:
      showMessage("Could not edit user !", success);
      return state;

    case RESET_USER_FORM: {
      if (action.payload) {
        let { contact } = action.payload;
        return state
          .set("values", initialState.get("values"))
          .setIn(["values", "contact"], contact);
      }
      return state
        .set("values", initialState.get("values"))
        .setIn(["errors"], { ...booleanSchema(true) });
    }

    case RESET_USER_FORM_NEW_USER: {
      let data = {
        contact: "",
        maxBalance: "",
        name: {
          fName: "",
          mName: "",
          lName: "",
        },
        company: "",
        role: "",
        dob: new Date(),
        doj: "",
        address: {
          l1: "",
          l2: "",
          city: "",
          pincode: "",
        },
        password: "",
        email: "",
        active: false,
        photoReq: false,
        userShiftDuty: {
          startTime: "",
          endTime: "",
        },
        expenseDepartment: "",
        userRef1Doc1:[],
        userRef1Doc2:[],
        userRef2Doc1:[],
        userRef2Doc2:[],
        designation: ""
      };
      return state.set("values", data);
    }
    case ASSIGN_BRANCH: {
      showMessage("Branches Assigned Successfully !", success);
      return state;
    }
    case ASSIGN_FLEET: {
      showMessage("Fleets Assigned Successfully !", success);
      return state;
    }
    case ASSIGN_BRANCH_FAILED: {
      showMessage("Couldn't Assign Branch !", failed);
      return state;
    }
    case ASSIGN_FLEET_FAILED: {
      showMessage("Couldn't Assign Fleet !", failed);
      return state;
    }
    default:
      return state;
  }
};
