// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { baseURL } from "../axios";
// import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
    apiKey: "AIzaSyDo4TQUb2r9A3-frOqlmhFglg5hGcYG5Ck",
    authDomain: "taptap-driver.firebaseapp.com",
    projectId: "taptap-driver",
    storageBucket: "taptap-driver.firebasestorage.app",
    messagingSenderId: "775014381957",
    appId: "1:775014381957:web:c8ff07dd875d9a438b6cec",
    measurementId: "G-D6MQH4PS79"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);
const vapidKey="BJ_vO-2MnCmKxmaXngr9LvOOxRBbE6Eq0Mu5PXtiq8eXWuIkdi-q3w5dMdKd8-347VBsYuUbRXtQrHxHuO9xPho"
const url = baseURL;
const sendTokenToServer = async (token:any) => {
    try {
      const response = await fetch(`${url}/register-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      });
  
      if (response.ok) {
        console.log("Token sent to server successfully.");
      }
    } catch (error) {
      console.error("Error sending token to server:", error);
    }
  };
  
console.log(`kSnvsndv`, sendTokenToServer);
  
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(async(registration) => {
      console.log('Service Worker registered successfully:', registration);
      return await getToken(messaging, {
        vapidKey: vapidKey,
        serviceWorkerRegistration: registration,
      });
     
    //   return token
    })
    .then((token) => {
      if (token) {
        console.log('Token generated:', token);
      } else {
        console.log('No token received. Permission may be denied.');
      }
      // sendTokenToServer(token)
    })
    .catch((error) => {
        // sendTokenToServer(error)
      console.error('Service Worker registration or token generation failed:', error);
    });
}