import { toastMessage } from "../Components/showMessages/toastMessage";

export const success = "#d4f7d4";
export const failed = "#fad1d1";
export const info = "#cfe6fc";

const x = document.getElementById("errormsg");

type PositionType =
  | "bottom-center"
  | "bottom-right"
  | "bottom-left"
  | "top-left"
  | "top-right"
  | "top-center"
  | "left-center"
  | "right-center";

interface ToastStyle {
  position: PositionType;
}

export function showMessage(
  msg: string,
  color: string = success,
  duration: number = 2000,
  toastStyle?: ToastStyle
) {
  if (!x) return;
  let textColor = "white";
  if(color === info) {
    textColor = "#020d18"
  } else if (color === failed) {
    textColor = "#2e0505"
  } else {
    textColor = "#082b08"
  }

  x.innerHTML = msg;
  x.style.backgroundColor = color;
  x.style.color = textColor;
  x.style.fontFamily = "Inter";
  x.style.fontWeight = "500";
  x.style.maxWidth = "600px";
  x.style.width = "calc(100% - 2rem)";
  x.className = "show";
  x.style.position = "fixed"; // Ensure the element is positioned correctly
  x.style.zIndex = "9999999999999999999999999"; // Ensure the toast appears above other elements

  const positionBase = "1rem";
  const resetPosition = () => {
    x.style.top = "";
    x.style.bottom = "";
    x.style.left = "";
    x.style.right = "";
    x.style.transform = "";
  };

  resetPosition();

  switch (toastStyle?.position) {
    case "bottom-center":
      x.style.bottom = positionBase;
      x.style.left = "50%";
      x.style.transform = "translateX(-50%)";
      break;
    case "bottom-right":
      x.style.bottom = positionBase;
      x.style.right = positionBase;
      break;
    case "bottom-left":
      x.style.bottom = positionBase;
      x.style.left = positionBase;
      break;
    case "top-left":
      x.style.top = positionBase;
      x.style.left = positionBase;
      break;
    case "top-center":
      x.style.top = positionBase;
      x.style.left = "50%";
      x.style.transform = "translateX(-50%)";
      break;
    case "top-right":
      x.style.top = positionBase;
      x.style.right = positionBase;
      break;
    case "left-center":
      x.style.top = "50%";
      x.style.left = positionBase;
      x.style.transform = "translateY(-50%)";
      break;
    case "right-center":
      x.style.top = "50%";
      x.style.right = positionBase;
      x.style.transform = "translateY(-50%)";
      break;
    default:
      x.style.left = "50%";
      x.style.bottom = positionBase;
      x.style.transform = "translateX(-50%)";
      break;
  }

  setTimeout(() => {
    x.className = x.className.replace("show", "");
  }, duration);
}

x?.addEventListener("click", () => {
  x.className = "";
});

export function showHttpError(err: any) {
  let message = err.message || err;
  if (err && err.response) {
    if (err.response.data) {
      message = err.response.data.message;
    } else {
      message = err.response.message;
    }
  }
  toastMessage(message, { type: "error" });
}

export function showError(err: any) {
  let message = err.message || err;
  if (err && err.response) {
    if (err.response.data) {
      message = err.response.data.message;
    } else {
      message = err.response.message;
    }
  }
  toastMessage(message,{type : "error"});
}

export default showMessage;