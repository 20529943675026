import { ServiceMaker, buildQueryString } from "./index";

export const getOperationsCount = (data: {
  branch: string;
  loading?: boolean;
  unloading?: boolean;
  delivery?: boolean;
  outforDelivery?: boolean;
  pendingPOD?: boolean;
  verifyStock?: boolean;
}) => ServiceMaker("report/operationcounts", "POST", data);

export const getOperationRequestCount = (data: any) =>
  ServiceMaker(`operation/pendingCounts?${buildQueryString(data)}`, "GET");

export const getGraceTowerCount = (data: any) =>
  ServiceMaker("controlTower/blockedCounts", "POST", data);

export const getGraceTowerData = (data: any) =>
  ServiceMaker(`controlTower/graceRequest/list?${buildQueryString(data)}`, "GET");

export const updateStatusOfGraceReq = (id : string, data: any) =>
  ServiceMaker(`controlTower/process/grace/${id}`, "PATCH" , data);

  export const getTotalRequestCount = (data:any)=>
   ServiceMaker(`operation/getTotalRequestCount?${buildQueryString(data)}`,"GET")
   
   export const getTotalRequestCountVerify = (data:any)=>
   ServiceMaker(`verification/getVerifyTotalRequestCount?${buildQueryString(data)}`,"GET")
 
 
   export const getTotalRequestCountCrm = (data:any)=>
   ServiceMaker(`customer/getCRMTotalRequestCount?${buildQueryString(data)}`,"GET")