import { fromJS } from "immutable";

import {
  CHANGE_SIMPLE,
  SET_FLEETS,
  SET_FLEET,
  SET_FRANCHISE_FLEETS,
  RESET,
  FLEET_CREATE_SUCCESS,
  FLEET_CREATE_FAIL,
  FLEET_EDIT_SUCCESS,
  FLEET_EDIT_FAIL,
  FLEET_DOOR_SUCCESS,
  FLEET_DOOR_FAIL,
} from "../constants/fleet";
import { showMessage, success } from "../utils/message";
import * as yup from "yup";
import { errorHandler } from "./helpers";
import { isArrayCheck } from "../containers/generics/CheckArray";

const fleetRegNumberRegex = /^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/;

const booleanSchema = (defaultValue: boolean) => ({
  regNumber: defaultValue,
  modelNumber: defaultValue,
  capacity: defaultValue,
  volume: {
    l: defaultValue,
    b: defaultValue,
    h: defaultValue,
  },
  fleetType: defaultValue,
  bodyType: defaultValue,
  fuelType: defaultValue,
  idealFuel: defaultValue,
  distanceCovered: defaultValue,
  ownerShip: defaultValue,
});

const validate = (schema: any, obj: any) => {
  try {
    if (!schema) return false;
    schema.validateSync(obj);
    return false;
  } catch (err: any) {
    console.log(err);
    return err.message;
  }
};

const initState = fromJS<any>({
  regNumber: "",
  shortName: "",
  modelNumber: "",
  manufacturer: "",
  capacity: "",
  volume: {
    l: "",
    b: "",
    h: "",
  },
  fleetType: {
    label: "Select FleetType",
    value: "",
  },
  assetSubType: "",
  active: true,
  docVerified: false,
  noOfSeats: "",
  fleetsMap: {},
  selectFleets: [],
  distanceCovered: "",
  bodyType: "",
  fuelType: "",
  ownerShip: "",
  fleets: [],
  idealFuel: "",
  insuranceDate: new Date(),
  fitnessDate: new Date(),
  permitDate: new Date(),
  emiDate: new Date(),
  franchiseFleets: [],
  franchiseFleetsMap: {},
  selectFranchiseFleets: [],
  allowedOFDCities: [],
  expenseDepartment: "",
  rcBook: {
    status:"P",
    image:""
  },
  allIndiaPermit: {
    status:"P",
    image:""
  },
  insurance: {
    status:"P",
    image:""
  },
  ownerPermission: {
    status:"P",
    image:""
  },
  rcBookImage: "",
  allIndiaPermitImage: "",
  insuranceImage: "",
  ownerPermissionImage: "",
  errors: { ...booleanSchema(true) },
});

const fleetSchemaValidate = fromJS({
  regNumber: yup
    .string()
    .required("RegNumber is required")
    .matches(fleetRegNumberRegex, "invalid format"),
  modelNumber: yup.string().required("Model number is required"),
  capacity: yup.string().required("Capactiy is required"),
  volume: {
    l: yup.string().required("Length is required"),
    b: yup.string().required("Breadth is required"),
    h: yup.string().required("Height is required"),
  },
  fleetType: yup.object().shape({
    label: yup.string().required("FleetType is required"),
    value: yup.string().required("FleetType is required"),
  }),
  bodyType: yup.object().shape({
    label: yup.string().required("Body Type is required"),
    value: yup.string().required("Body Type is required"),
  }),
  fuelType: yup.object().shape({
    label: yup.string().required("Fuel Type is required"),
    value: yup.string().required("Fuel Type is required"),
  }),
  ownerShip: yup.object().shape({
    label: yup.string().required("Ownership is required"),
    value: yup.string().required("Ownership is required"),
  }),
  assetSubType: yup.string().required("assetSubType is required"),
  // bodyType: yup.string().required("BodyType is required"),
  // fuelType: yup.string().required("FuelType is required"),
  // ownerShip: yup.string().required("OwnerShip is required"),
  idealFuel: yup.string().required("IdealFuel is required"),
  distanceCovered: yup.string().required("DistanceCovered is required"),
  allowedOFDCities: yup
    .array()
    .of(yup.string().required())
    .min(1, "At least 1 allowedOFDCities is required"),
  businessType: yup.string().required("BusinessType is required"),
  overhead: yup.string().required("overhead is required"),
  insuranceDate: yup.date().required("insuranceDate is required"),
  emiDate: yup.date().required("emiDate is required"),
  fitnessDate: yup.date().required("fitnessDate is required"),
  permitDate: yup.date().required("permitDate is required")
});

export default (state = initState, action: { type: any; payload: any }) => {
  switch (action.type) {
    case CHANGE_SIMPLE: {
      const { what, val } = action.payload;
      return state
        .setIn(what, val)
        .setIn(
          ["errors", ...what],
          validate(fleetSchemaValidate.getIn(what), val)
        );
    }

    case "CHANGE_OFD_CITIES": {
      let allowedOFDCities = [];
      allowedOFDCities.push(action.payload);
      return state.set("allowedOFDCities", allowedOFDCities[0]);
    }
    case "SET_FLEET_ERROR": {
      return state.setIn(
        ["errors", ...action.payload],
        validate(fleetSchemaValidate.getIn(action.payload), "")
      );
    }

    case SET_FLEETS: {
      let fleetsMap: any = {},
        selectFleets: any = [];
      if (isArrayCheck(action.payload)) {
        action.payload.forEach((e: { regNumber: any }) => {
          fleetsMap[e.regNumber] = e;
          if (e.regNumber) {
            selectFleets.push({ label: e.regNumber, value: e.regNumber });
          }
        });
      }
      return state
        .set("fleets", action.payload)
        .set("fleetsMap", fleetsMap)
        .set("selectFleets", selectFleets)
        .set("errors", { ...booleanSchema(true) });
    }

    case SET_FRANCHISE_FLEETS: {
      let fleetsMap: any = {},
        selectFranchiseFleets: any = [];
      if (isArrayCheck(action.payload)) {
        action.payload.forEach((e: { [k: string]: any }) => {
          fleetsMap[e.regNumber] = e;
          if (e.regNumber) {
            selectFranchiseFleets.push({
              label: e.regNumber + " " + e.companyName,
              value: e.regNumber,
              company: e.companyId,
            });
          }
        });
      }
      return state
        .set("franchiseFleets", action.payload)
        .set("franchiseFleetsMap", fleetsMap)
        .set("selectFranchiseFleets", selectFranchiseFleets);
    }

    case SET_FLEET: {
      const { _id } = action.payload;
      return state
        .merge({
          ...action.payload,
          _id: _id,
        })
        .set("errors", { ...booleanSchema(true) })
    }

    case RESET: {
      return initState;
    }

    case FLEET_CREATE_SUCCESS: {
      showMessage("Fleet created successfully", success);
      return state;
    }

    case FLEET_CREATE_FAIL: {
      errorHandler(action.payload);
      return state;
    }

    case FLEET_EDIT_SUCCESS: {
      showMessage("Fleet edited successfully", success);
      return state;
    }

    case FLEET_EDIT_FAIL: {
      errorHandler(action.payload);
      return state;
    }

    case FLEET_DOOR_SUCCESS: {
      showMessage("Fleet Door successfully Created", success);
      return state;
    }

    case FLEET_DOOR_FAIL: {
      errorHandler(action.payload);
      return state;
    }

    default: {
      return state;
    }
  }
};
