import { Dispatch } from "redux";
import {
  RESET_OPERATIONS_COUNT,
  UPDATE_OPERATIONS_ALL_COUNT,
  UPDATE_OPERATIONS_DELIVERY_COUNT,
  UPDATE_OPERATIONS_LOADING_COUNT,
  UPDATE_OPERATIONS_PENDINGPOD_COUNT,
  UPDATE_OPERATIONS_UNLOADING_COUNT,
  UPDATE_OPERATIONS_VERIFYSTOCKS_COUNT,
  UPDATE_POD_COUNT,
  UPDATE_VERIFY_CANCEL_BOOKING,
  UPDATE_VERIFY_EXPENSE_BADGES,
  UPDATE_VERIFY_SHORTAGE,
  UPDATE_VERIFY_STOCK,
  UPDATE_PETTY_CASH,
  UPDATE_REQUEST_OPERATION,
  UPDATE_CREDIT_SETTLEMENT_COUNT,
  GET_GRACETOWER_COUNT,
  FEEDBACK_COUNT,
  UPDATE_COMPLAINTS_CHAT,
  CAMERA_STATUS_DETAILS,
  ISSUES_PENDING_COUNT,
  SET_ISSUE_COUNTS,
} from "../constants/sidebarBadges";
import { getUnverifiedExpenseCount } from "../services/verifyPOD";
import { RootState } from "../store";
import {
  getOperationsCount,
  getOperationRequestCount,
  getGraceTowerCount,
} from "../services/operationsCount";
import { countSettlementExpenseAmount } from "../services/vendor";

export const updateVerifyExpenseBadges = () => {
  return async (dispatch: Dispatch) => {
    try {
      const {
        // total,
        pendingVerify,
        pendingVoucherImage,
      } = await getUnverifiedExpenseCount();
      dispatch({
        type: UPDATE_VERIFY_EXPENSE_BADGES,
        payload: {
          pendingVerify,
          pendingVoucherUpload: pendingVoucherImage,
        },
      });
    } catch (err : any) {}
  };
};

export const setVerifyExpenseBadgeCounts = (
  pendingVerify: number,
  pendingVoucherUpload: number
) => {
  return {
    type: UPDATE_VERIFY_EXPENSE_BADGES,
    payload: {
      pendingVerify,
      pendingVoucherUpload,
    },
  };
};

export const decreasePendingVerifyCount = () => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const {
      pendingVerify,
      pendingVoucherUpload,
    } = getState().sidebarBadges.verify.expenses;
    if (typeof pendingVerify === "number" && pendingVerify > 0) {
      dispatch({
        type: UPDATE_VERIFY_EXPENSE_BADGES,
        payload: {
          pendingVerify: pendingVerify - 1,
          pendingVoucherUpload,
        },
      });
    }
  };
};

export const decreasePendingVoucherUploadCount = () => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const {
      pendingVerify,
      pendingVoucherUpload,
    } = getState().sidebarBadges.verify.expenses;
    if (typeof pendingVoucherUpload === "number" && pendingVoucherUpload > 0) {
      dispatch({
        type: UPDATE_VERIFY_EXPENSE_BADGES,
        payload: {
          pendingVerify,
          pendingVoucherUpload: pendingVoucherUpload - 1,
        },
      });
    }
  };
};

export const updateVerifyCancelBookingBadges = (data: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UPDATE_VERIFY_CANCEL_BOOKING,
        payload: data,
      });
    } catch (err : any) {}
  };
};

export const updatePodCount = (data: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UPDATE_POD_COUNT,
        payload: data,
      });
    } catch (err : any) {}
  };
};
export const updateVerifyStockBadges = (data: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UPDATE_VERIFY_STOCK,
        payload: data,
      });
    } catch (err : any) {}
  };
};

export const updatePettyCashBadges = (data: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UPDATE_PETTY_CASH,
        payload: data,
      });
    } catch (err : any) {}
  };
};

export const updateComplaintsChatBadges = (data: any) => {
  
  return (dispatch: Dispatch) => {
      dispatch({
        type: UPDATE_COMPLAINTS_CHAT,
        payload: data,
      });
    };
};

export const updateCameraStatusDetails = (data: any) => {
  
  return (dispatch: Dispatch) => {
      dispatch({
        type: CAMERA_STATUS_DETAILS,
        payload: data,
      });
    };
};

export const updateAllIssueCounts = (data: any) => {
  
  return (dispatch: Dispatch) => {
      dispatch({
        type: SET_ISSUE_COUNTS,
        payload: data,
      });
    };
};

export const updateIssuesCount = (data: any) => {
  
  return (dispatch: Dispatch) => {
      dispatch({
        type: ISSUES_PENDING_COUNT,
        payload: data,
      });
    };
};

export const updateVerifyShortageBadges = (data: number) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: UPDATE_VERIFY_SHORTAGE,
        payload: data,
      });
    } catch (err : any) {}
  };
};

export const updateOperationsAllCount = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    try {
      if (getState().user.loginType === "B") {
        const {
          loading,
          unloading,
          delivery,
          verifyPackages,
        } = getState().rights.operations;

        let operationPayload: any = {};
        operationPayload = {
          branch: getState().user.opBranch._id,
        };
        if (loading) {
          operationPayload.loading = true;
        }
        if (unloading) {
          operationPayload.unloading = true;
        }
        if (delivery) {
          operationPayload.delivery = true;
          operationPayload.outforDelivery = true;
          operationPayload.pendingPOD = true;
        }
        if (verifyPackages) {
          operationPayload.verifyStock = true;
        }

        getOperationsCount(operationPayload)
          .then((response) => {
            dispatch({
              type: UPDATE_OPERATIONS_ALL_COUNT,
              payload: response,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err : any) {}
  };
};

export const updateOperationsLoadingCount = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    try {
      const { loading } = getState().rights.operations;
      if (getState().user.loginType === "B" && loading) {
        let operationPayload: any = {};
        operationPayload = {
          branch:
            getState().user.loginType === "B"
              ? getState().user.opBranch._id
              : getState().user.opFleet._id,
          loading: true,
        };

        getOperationsCount(operationPayload)
          .then((response) => {
            dispatch({
              type: UPDATE_OPERATIONS_LOADING_COUNT,
              payload: response,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err : any) {}
  };
};
export const OperationsRequestCount = (operationData:any = "NoData") => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      // const { loading } = getState().rights.operations;
      // if (g {
      const operationsRights = getState().rights.operationRequest;

      let operationPayload: any = {};
      operationPayload = {
        entity:
          getState().user.loginType === "B"
            ? getState().user.opBranch._id
            : getState().user.opFleet._id,
        sub: getState().user.loginType,
        BTH: operationsRights.backToHub.view ? operationData =="NoData" ?true : operationData?.BTH  ? true:false : false,
        ND: operationsRights.changeDestination.view ? operationData =="NoData" ?true : operationData?.ND  ? true:false : false,
        demurrage: operationsRights.damurrage.view ? operationData =="NoData" ?true : operationData?.demurrage  ? true:false : false,
        remarks: operationsRights.remarksRequest.view ? operationData =="NoData" ?true : operationData?.remarks  ? true:false : false,
        discount: operationsRights.discount.view ? operationData =="NoData" ?true : operationData?.discount  ? true:false : false,
        doorDeliveryDiscount: operationsRights.discount.view ? operationData =="NoData" ?true : operationData?.doorDeliveryDiscount  ? true:false : false,
        customerConnect: operationData =="NoData" ?true : operationData?.customerConnect  ? true:false,
        claim: operationsRights.claimSettlement.view ? operationData =="NoData" ?true : operationData?.claim  ? true:false : false,
        parcelTransfer: operationsRights.parcelTransferRequest.view ? operationData =="NoData" ?true : operationData?.parcelTransfer  ? true:false : false,
        cancelDoorDeliveryBooking: operationData =="NoData" ?true : operationData?.cancelDoorDeliveryBooking  ? true:false,
        cancelBooking: operationsRights.cancelBookings.view ? operationData =="NoData" ?true : operationData?.cancelBooking  ? true:false : false,
        fleetOrderRecheckIn: operationsRights.reCheckInReq.view ? operationData =="NoData" ?true : operationData?.fleetOrderRecheckIn  ? true:false : false,
        branchDelivery: operationsRights.branchDelivery.view ? operationData =="NoData" ?true : operationData?.branchDelivery  ? true:false : false,
        packageUpdate:operationsRights.packageUpdateRequestList.view ? operationData =="NoData" ?true : operationData?.packageUpdate  ? true:false:false
      };

      const response = await getOperationRequestCount(operationPayload);

      dispatch({
        type: UPDATE_REQUEST_OPERATION,
        payload: response,
      });
      // }
    } catch (err : any) {}
  };
};

export const creditorSettlememtCount = () => {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const operationPayload = {
        entity:
          getState().user.loginType === "B"
            ? getState().user.opBranch._id
            : getState().user.opFleet._id,
        sub: getState().user.loginType,
      };

      const response = await countSettlementExpenseAmount(operationPayload);
      console.log("responseresponse", response);

      dispatch({
        type: UPDATE_CREDIT_SETTLEMENT_COUNT,
        payload: response,
      });
      // }
    } catch (err : any) {}
  };
};
//

export const graceTowerCountFromModule = (data: any) => {
  return (dispatch: Dispatch) => {
    try {
      dispatch({
        type: GET_GRACETOWER_COUNT,
        payload: data,
      });
    } catch (err : any) {}
  };
};

export const graceToweCount = () => {
  return async (dispatch: Dispatch) => {
    try {
      // const { loading } = getState().rights.operations;
      // if (g {

      let operationPayload: any = {};
      operationPayload = {
        // entity:
        //   getState().user.loginType === "B"
        //     ? getState().user.opBranch._id
        //     : getState().user.opFleet._id,
        // sub: getState().user.loginType,

        company: true,
        user: true,
        creditCoustomer: true,
        branch: true,
        fleet: true,
      };

      getGraceTowerCount(operationPayload).then((response) => {
        dispatch({
          type: GET_GRACETOWER_COUNT,
          payload: response,
        });
      });
      // }
    } catch (err : any) {}
  };
};

export const updateOperationsUnloadingCount = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    try {
      const { unloading } = getState().rights.operations;
      if (getState().user.loginType === "B" && unloading) {
        let operationPayload: any = {};
        operationPayload = {
          branch:
            getState().user.loginType === "B"
              ? getState().user.opBranch._id
              : getState().user.opFleet._id,
          unloading: true,
        };

        getOperationsCount(operationPayload)
          .then((response) => {
            dispatch({
              type: UPDATE_OPERATIONS_UNLOADING_COUNT,
              payload: response,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err : any) {}
  };
};

export const updateOperationsDeliveryCount = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    try {
      const { delivery } = getState().rights.operations;
      if (getState().user.loginType === "B" && delivery) {
        let operationPayload: any = {};
        operationPayload = {
          branch: getState().user.opBranch._id,
          delivery: true,
          outforDelivery: true,
        };

        getOperationsCount(operationPayload)
          .then((response) => {
            dispatch({
              type: UPDATE_OPERATIONS_DELIVERY_COUNT,
              payload: response,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err : any) {}
  };
};

export const updateOperationsPendingPODCount = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    try {
      const { delivery } = getState().rights.operations;
      if (getState().user.loginType === "B" && delivery) {
        let operationPayload: any = {};
        operationPayload = {
          branch: getState().user.opBranch._id,
          pendingPOD: true,
        };

        getOperationsCount(operationPayload)
          .then((response) => {
            dispatch({
              type: UPDATE_OPERATIONS_PENDINGPOD_COUNT,
              payload: response,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err : any) {}
  };
};

export const updateOperationsVerifystocksCount = () => {
  return (dispatch: Dispatch, getState: () => any) => {
    try {
      const { verifyPackages } = getState().rights.operations;
      if (getState().user.loginType === "B" && verifyPackages) {
        let operationPayload: any = {};
        operationPayload = {
          branch: getState().user.opBranch._id,
          verifyStock: true,
        };

        getOperationsCount(operationPayload)
          .then((response) => {
            dispatch({
              type: UPDATE_OPERATIONS_VERIFYSTOCKS_COUNT,
              payload: response,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err : any) {}
  };
};

export const resetOperatingCount = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: RESET_OPERATIONS_COUNT,
    });
  };
};

export const updateFeedbackCount = (data: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: FEEDBACK_COUNT,
        payload: data,
      });
    } catch (err : any) {}
  };
};
