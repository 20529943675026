import { ServiceMaker, buildQueryString } from "./index";
import jsonToFormdata from "../utils/jsonToFormdata";

export const getUserByContact = (contact: number, franchise?: any , authentication?: boolean) => {
  console.log(contact,authentication,"authentication")
  return ServiceMaker("user/view/contact", "POST", {
    contact,
    franchise,
    authentication
  });
}

export const assignBranchService = (data: {
  userId: string;
  branches: [string];
}) => ServiceMaker("user/branch/assign", "POST", data);

export const deleteApiService = () => ServiceMaker("user/deactive", "PATCH");

export const assignFleetService = (data: {
  userId: string;
  fleets: [string];
}) => ServiceMaker("user/fleet/assign", "POST", data);

export const listUsersService = (data?: any) =>
  ServiceMaker("user/list", "POST", data);

export const userDetailedList = (data: any) =>
  ServiceMaker(`user/detailedList?${buildQueryString(data)}`, "GET");

export const userDetailedListForDevTest = (data: any) =>
  ServiceMaker(`user/detailedListForDevTest?${buildQueryString(data)}`, "GET");
export const getUserById = (id: string) =>
  ServiceMaker(`user/get/${id}`, "GET");

// export const viewUser = (user, company) => ServiceMaker('user/view', 'POST', { user, company })

export const getDrivers = (company: string) =>
  ServiceMaker("user/driver/" + company, "GET");

export const getUsersByIds = (data: { arr: [string] }) =>
  ServiceMaker("user/ids", "POST", data);

export const deleteAssignedBranchService = (data: {
  userId: string;
  branches: [string];
  company: string;
}) => ServiceMaker("user/branch/delete", "POST", data);

export const deleteAssignedFleetService = (data: {
  userId: string;
  fleets: [string];
}) => ServiceMaker("user/fleet/delete", "POST", data);

export const deleteAssignedDeptService = (data: {
  userId: string;
  departments: [string];
}) => ServiceMaker("user/depart/remove", "POST", data);

export const departmentListService = (companyId: string) =>
  ServiceMaker("department/nameList/" + companyId, "GET");

export const assignDepartmentService = (data: {
  userId: string;
  departments: [string];
}) => ServiceMaker("user/depart/assign", "POST", data);

export const updateUserService = (data: any, id: string) =>
{
  return ServiceMaker(
    "user/update/" + id,
    "PUT",
    jsonToFormdata({
      active: data.active,
      photoReq:data.photoReq,
      address: data.address,
      companyId: data.companyId,
      contact: data.contact,
      createdAt: data.createdAt,
      dob: data.dob,
      doj: data.doj,
      email: data.email,
      maxBalance: data.maxBalance,
      name: data.name,
      role: data.role,
      type: data.type,
      userShiftDuty: data.userShiftDuty,
      // userProfile: data.userProfile,
      docVerified:data.docVerified,
      panCardImage: data.panCardImage,
      aadharCardImage: data.aadharCardImage,
      salarySlipImage: data.salarySlipImage,
      bankStatementImage: data.bankStatementImage,
      agreementImage: data.agreementImage,
      licenceImage: data.licenceImage,
      userProfileImage: data.userProfileImage,
      userTypeId: data.userTypeId,
      expenseDepartment: data.expenseDepartment,
      businessType: data.businessType,
      overhead: data.overhead,
      reportingManager: data.reportingManager,
      bannerImage: data.bannerImage,
      // userProfile: {
      //   verified:data.userProfileVerified,
      // },
      panCard: data?.panCard,
      aadharCard: data?.aadharCard,
      userProfile: data?.userProfile,
      salarySlip: data?.salarySlip,
      bankStatement: data?.bankStatement,
      agreement: data?.agreement,
      licence: data?.licence,
      // panCard: {

      //   verified:data.panCardVerified,
      // },
      // aadharCard:{
      //   verified:data.aadharCardVerified,
      // } ,
      // salarySlip:{
      //   verified:data.salarySlipVerified,
      // } ,
      // bankStatement:{
      //   verified: data.bankStatementVerified,
      // },
      // agreement:{
      //   verified:data.agreementVerified,
      // } ,
      // licence:{
      //   verified:data.licenceVerified,
      // } ,
      referenceUsers:data.referenceUsers,
      userRef1Doc1:data.userRef1Doc1,
      userRef1Doc2:data.userRef1Doc2,
      userRef2Doc1:data.userRef2Doc1,
      userRef2Doc2:data.userRef2Doc2,
      paidLeaveCount: data.pendingPaidLeaveCount,
      bankDetails: data.bankDetails,
      designation: data.designation
    })
  );
}
export const updateUserDriver = (data: any, id: string) =>
  ServiceMaker(
    "user/update/" + id,
    "PUT",
    jsonToFormdata(
      data
    )
  );

export const userAllBalancesService = (id: string) =>
  ServiceMaker("expense/user/balance/show/" + id, "GET");

export const createUserService = (data: any) =>
  ServiceMaker(
    "user/create",
    "POST",
    jsonToFormdata({
      active: data.active,
      photoReq:data.photoReq,
      address: data.address,
      companyId: data.companyId,
      contact: data.contact,
      createdAt: data.createdAt,
      dob: data.dob,
      doj: data.doj,
      paidLeaveCount: data.pendingPaidLeaveCount,
      email: data.email,
      maxBalance: data.maxBalance,
      name: data.name,
      role: data.role,
      type: data.type,
      userShiftDuty: data.userShiftDuty,
      userProfileImage: data.userProfile,
      panCardImage: data.panCard,
      aadharCardImage: data.aadharCard,
      salarySlipImage: data.salarySlip,
      bankStatementImage: data.bankStatement,
      agreementImage: data.agreement,
      userTypeId: data.userTypeId,
      expenseDepartment: data.expenseDepartment,
      businessType: data.businessType,
      overhead: data.overhead,
      reportingManager: data.reportingManager,
      licenceImage: data.licence,
      userProfile: {
        verified:data.userProfileVerified,
      },
      panCard: {
        verified:data.panCardVerified,
      },
      aadharCard:{
        verified:data.aadharCardVerified,
      } ,
      salarySlip:{
        verified:data.salarySlipVerified,
      } ,
      bankStatement:{
        verified: data.bankStatementVerified,
      },
      agreement:{
        verified:data.agreementVerified,
      } ,
      licence:{
        verified:data.licenceVerified,
      } ,
      referenceUsers:data.referenceUsers,
      userRef1Doc1:data.userRef1Doc1,
      userRef1Doc2:data.userRef1Doc2,
      userRef2Doc1:data.userRef2Doc1,
      userRef2Doc2:data.userRef2Doc2,
      bankDetails: data.bankDetails,
      designation: data.designation
    })
  );

export const listUsersActivityService = (data: any) =>
  ServiceMaker("userAction/list", "POST", data);

export const getNotificationService = (data: {
  offset?: number;
  limit?: number;
}) => ServiceMaker("notification/getNotification", "POST", data);

export const getCustomerNotificationService = (data: {
  offset?: number;
  limit?: number;
}) => ServiceMaker("customerNotification/getNotifications", "POST", data);

export const getCustomerPendingActionNotificationCount = () =>
  ServiceMaker("customerNotification/getPendingnotificationsCount", "GET");

export const setNotificationConfiguration = (data : any) => ServiceMaker("notification/getNotification", "POST", data);

export const getPendingActionNotificationCount = () =>
  ServiceMaker("notification/getpendingnotificationcount", "GET");

export const listMemoReceiver = (data: any) =>
  ServiceMaker(`user/memoReceiver?${buildQueryString(data)}`, "GET");

export const PettyCash = (data: any) =>
  ServiceMaker(
    `transactions/availableTableCash?${buildQueryString(data)}`,
    "GET"
  );

export const listAllUsersService = (data?: any) =>
  ServiceMaker("user/list", "POST", data);

export const listAllUsersForCustomers = (data?: any) =>
  ServiceMaker("customerBooking/listUsers", "POST", data);

export const listAllUser = (data?: any) =>
  ServiceMaker(`user/allUser?${buildQueryString(data)}`, "GET");

export const getActiveInactiveUser = (data?: any) =>
  ServiceMaker("user/listBoth", "POST", data);

export const listUserActivity = (data: any) =>
  ServiceMaker(`report/userActivity?${buildQueryString(data)}`, "GET");

 export const userActivityEfficiency = (data:any) =>
      ServiceMaker(`report/userActivityEfficiency?${buildQueryString(data)}`, "GET");
  
 export const userActivityEfficiencyDaywise = (data:any) =>
      ServiceMaker(`report/userActivity/dayWise?${buildQueryString(data)}`, "GET");
  
 export const getDriverRating = (data:any) =>
      ServiceMaker(`user/getDriverRating?${buildQueryString(data)}`, "GET");
  
 export const getDriverProfile = (data:any) =>
      ServiceMaker(`user/getDriverProfile?${buildQueryString(data)}`, "GET");

export const addUserAttachment = (data:any) => 
    ServiceMaker(`user/addUserAttachment`, "PATCH", data);

export const getIndividualWithFleetUsers = (data: any) => ServiceMaker(`user/iwf?${buildQueryString(data)}`, "GET");