import { ServiceMaker, buildQueryString } from "./index";

export const getVendorList = (data?: Object) => ServiceMaker(`vendor/list?${buildQueryString(data || {})}`, "GET");

export const createVendor = (data: any) =>
  ServiceMaker("vendor/create", "POST", data);

export const getVendorContactByName = (data : any) => ServiceMaker(`/vendor/getPhoneNumberByName?${buildQueryString(data)}`, "GET");

export const sendOtpVendor = (data : any) => ServiceMaker(`vendor/sendOtp?${buildQueryString(data)}`, "POST");

export const verifyOtpVendor = (data : any) => ServiceMaker(`/vendor/getOptVerify?${buildQueryString(data)}`, "GET",);


export const updateVendor = (data: any) =>
  ServiceMaker("vendor/update", "PUT", data);

export const viewVendor = (data: any) =>
  ServiceMaker(`vendor/view?${buildQueryString(data)}`, "GET");

export const getExpenseList = () => ServiceMaker("expense/list", "POST");

export const gethandlingCountSync  = (data : any) => ServiceMaker("handling/handlingCountSync", "PATCH",data);

export const fetchCities = () => ServiceMaker("city/list", "GET");

export const getCityByPincode = (data: any) =>
  ServiceMaker(`area/city?${buildQueryString(data)}`, "GET");

export const listVendorByExpenseType = (data: any) =>
  ServiceMaker(`vendor/listByExpenseType?${buildQueryString(data)}`, "GET");



export const getSettlementExpenses = (data: any) =>
  ServiceMaker(
    `income/filterSettlementIncome?${buildQueryString(data)}`,
    "GET"
  );
export const creditSettlementExpenses = (data: any) =>
  ServiceMaker("income/settleCreditorIncome", "PATCH", data);
export const settleCreditorIncome = (data: any) =>
  ServiceMaker(
    `income/filterSettlementIncome?${buildQueryString(data)}`,
    "GET"
  );

export const getCreditSettlement = (data: any) =>
  ServiceMaker(
    `vendor/filterSettlementExpenses?${buildQueryString(data)}`,
    "GET"
  );

export const settleCreditExpenses = (data: any) =>
  ServiceMaker("vendor/settleCreditExpenses", "POST", data);

  export const countSettlementExpenseAmount = (data: any) =>
  ServiceMaker(`income/settlementIncomeAmt?${buildQueryString(data)}`,"GET");