import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./../src/containers/CustomerSection/CustomerBookingTracking/customerBooking.css";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import "./newSrc/styles/colors.css";
import "./newSrc/styles/utils.css";
import Loader from "./Components/Loader/Loader";
import { toast } from "react-toastify";
import { onMessage } from "firebase/messaging";
import { messaging } from "./utils/firebase";
const AllRoutes = lazy(() => import("./router"));
// import { subscriptionNotification } from "./subscriptionNotification";

import * as Sentry from "@sentry/react";
const tagManagerArgs = {
  gtmId: "GTM-WXPKHMBM",
};
TagManager.initialize(tagManagerArgs);


Sentry.init({
  dsn: "https://5174e2c1ecaec313c3836b01908e410b@o4508596721549312.ingest.us.sentry.io/4508596723646464",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["https://taptap.in", /^https:\/\/api\.taptap\.in\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function App() {
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);


  // TODO this is for notification and it is required so dont remove this comment

  // const requestNotification = async () => {
  //   const notification = await Notification.requestPermission()

  //   if (notification === 'granted') {
  //     // const token = await getToken(messaging,{vapidKey:"BBLbfdSNjhA7ykGx3RDd1-OaBKoqE_k5PkJ1_ZZgNb_s9xzHxm3bUHAL9X5x4KUbDzgSaXQg01OXlxhvSG7nJe4"})
  //     subscriptionNotification()
  //   }
  //   else if (notification === 'denied') {
  //     // alert("you deinied notification permission")
  //     localStorage.removeItem("pubKey")
  //     // unsubscribeFromFCM()
  //   }
  //   else {
  //     // unsubscribeFromFCM()
  //   }
  // }

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setIsLoaderVisible(false);
    }, 2000);
    // requestNotification();

    return () => clearTimeout(loaderTimeout);
  }, []);

  useEffect(() => {
    onMessage(messaging, (payload: any) => {
      console.log(payload);
      toast(payload?.notification?.body);
    });
  }, [])


  // TODO this socket is for notification and it is required so dont remove this comment

  // socket.on("keyUpdated", (_data : any) => {
  //   localStorage.removeItem("pubKey");
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === "granted") {
  //       updateSubscriptionNotification()
  //     }
  //   });
  // })

  return (
    <>
      {isLoaderVisible 
        ? <Loader /> 
        : <Suspense fallback={<Loader />}>
            <Router>
              <AllRoutes />
            </Router>
          </Suspense>
      }
    </>
  );
}

export default App;
