import React from "react";
import { createRoot } from "react-dom/client"; // Change import here
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import store from "./store";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga";
import "./console-disable";
import swDev from "./swDev";
import ErrorBoundary from "./Components/ErrorHandling/ErrorBoundary/ErrorBoundary";
// import { PersistGate } from "redux-persist/integration/react";

const TRACKING_ID = "UA-257764354-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const rootElement: any = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  ReactDOM.hydrate(
    <ErrorBoundary>
      <ToastContainer style={{ zIndex: "10000000" }} />
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
          <App />
        {/* </PersistGate> */}
      </Provider>
    </ErrorBoundary>,
    rootElement
  );
} else {
  createRoot(rootElement).render(
    // Use createRoot instead of ReactDOM.render
    <ErrorBoundary>
      <ToastContainer style={{ zIndex: "10000000" }} />
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
          <App />
        {/* </PersistGate> */}
      </Provider>
    </ErrorBoundary>
  );
}

swDev();
