export const fleetRegNumberRegex = /^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/;

export const messageRegex = /^[a-zA-Z0-9 ,./?;':"<>\[\]{}\\|()!@#$%&*-_+=\n]+$/;

// 1st december
export const voucherImageRelaxationDate = new Date(2020, 11, 1, 0, 0, 0, 0);
// 10-December 2020
export const verifyPackagesEnforceDate = new Date(2021, 11, 10, 0, 0, 0, 0);
// Package must be verified every before 8 days of last verification
export const verifyPackageThreshold = 8;

// verify docket receiver by otp from 24-dec
export const verifyDocketReceiverRelaxationDate = new Date(
  2020,
  11,
  24,
  0,
  0,
  0,
  0
);

export const creditorType = [
  { label: "All", value: "" },
  { label: "PayTM", value: "PayTM" },
  { label: "RedBus", value: "RedBus" },
  { label: "Mantis", value: "Mantis" },
  { label: "Branch", value: "Branch" },
  { label: "Agent", value: "Agent" },
  { label: "Other", value: "Other" },
];

export const sendMemoRestriction = 10;

export const denominator = 2700;

export const reCaptchaKey = "6Ld5ft8bAAAAADAjHq8-bSS3meE2QVvRc7Yu0egS";

export const gstRegex=/(\d{2}.{10}\d[A-Z].)|^$/

export const bookingGstForceNameUpdateReq = false
export const bookingMaxGstCount = 5 
export const bookingMaxSecondaryContactCount = 4
export const bookingMaxNameCount = 3

export const COLOR = {
  PRIMARY_50: "#ccebff",
  PRIMARY_100: "#1aa3ff",
  PRIMARY_400: "#007acc",
  PRIMARY_900: "##002e4d",
}